import {
  Box, Button, Container, Flex, Heading, Icon, Text,
} from '@chakra-ui/react';
import MainLayout from '@src/components/layouts/main/MainLayout';
import LoadingSpinner from '@src/components/shared/LoadingSpinner';
import {
  useActivityManagementPlans,
  useMiscActivityManagementPlans,
} from '@src/modules/performance/context/reports-api-hooks';
import { FiDownloadCloud } from 'react-icons/fi';
import { currentDatabaseSelector } from '@src/state/navContext';
import { useRecoilValue } from 'recoil';
import usePerformanceReportsApi from '../../api/usePerformanceReportsApi';

export default function ActivityManagementPlans() {
  const { data: ampData, isLoading: ampIsLoading } = useActivityManagementPlans();
  const { data: miscAmpData } = useMiscActivityManagementPlans();
  const api = usePerformanceReportsApi();

  const files = ampData ?? [];
  const miscFiles = miscAmpData ?? [];
  const currentDatabase = useRecoilValue(currentDatabaseSelector);

  const rcaId = currentDatabase?.id;
  const rcaName = currentDatabase?.name;

  const getFileName = (file) => {
    if (file.IsUniversal === true) {
      return new RegExp(`^(.*).${file.FileExtension}`).exec(file.FileName)[1]; // Use provided file name if RCA is ALL
    }

    return `${file.Year}/${file.Year + 3 - 2000} AMP Review for ${rcaName}`;
  };

  const downloadFile = (file) => {
    api.downloadActivityManagementPlan(rcaId, file.DownloadLink)
      .then((res) => {
        const blob = new Blob(
          [res],
          { type: res.type },
        );
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `${getFileName(file)}.${file.FileExtension}`;
        link.click();
      });
  };

  return (
    <MainLayout backgroundColor="gray.50">
      <Container maxW="full">
        <Heading as="h1" mb={6}>AMP Review</Heading>
        {ampIsLoading && <LoadingSpinner />}
        {!ampIsLoading
          && (
          <Box mt={8} variant="soft-rounded" size="md">
            {files.map((file) => (
              <Button
                key={file.FileName}
                display="flex"
                w="100%"
                justifyContent="space-between"
                size="lg"
                variant="outline"
                colorScheme="blue"
                mb={4}
                onClick={() => downloadFile(file)}
              >
                <Flex as="span" align="center">
                  <Icon as={FiDownloadCloud} boxSize={8} mr={4} />
                  <Text fontSize="lg" fontWeight="normal">
                    {getFileName(file)}
                  </Text>
                </Flex>
                <Text fontSize="sm" fontWeight="normal">
                  {file.FileExtension.toUpperCase()}
                </Text>
              </Button>
            ))}
            {files.length === 0 && <Text>No reports available.</Text>}

            {/* Show the "Miscellaneous" section if there are any files to show */}
            {miscFiles.map((file) => (
              <Button
                key={file.FileName}
                display="flex"
                w="100%"
                justifyContent="space-between"
                size="lg"
                variant="outline"
                colorScheme="blue"
                mb={4}
                onClick={() => downloadFile(file)}
              >
                <Flex as="span" align="center">
                  <Icon as={FiDownloadCloud} boxSize={8} mr={4} />
                  <Text fontSize="lg" fontWeight="normal">
                    {getFileName(file)}
                  </Text>
                </Flex>
                <Text fontSize="sm" fontWeight="normal">
                  {file.FileExtension.toUpperCase()}
                </Text>
              </Button>
            ))}
          </Box>
          )}
      </Container>
    </MainLayout>
  );
}
