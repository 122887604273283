import { useState } from 'react';
import {
  Flex,
  Icon,
  Input,
  IconButton,
} from '@chakra-ui/react';
import { FiSearch } from 'react-icons/fi';
import { useConfig } from '@src/context/config';

export default function SearchBar() {
  const config = useConfig();
  const [searchQuery, setSearchQuery] = useState('');
  const handleChange = (event) => setSearchQuery(event.target.value);

  const handleSearch = () => {
    if (searchQuery) {
      window.location.assign(`${config.LEGACY_PMRT_URL}/Home/SearchResults/${searchQuery}`);
    }
  };

  return (
    <Flex boxShadow="1px 1px 3px rgba(0,0,0,0.15)">
      <Input
        value={searchQuery}
        onChange={handleChange}
        colorScheme="whiteAlpha"
        placeholder="Search..."
        variant="solid"
        backgroundColor="rgba(255,255,255,0.3)"
        _placeholder={{ color: 'rgba(0,0,0,0.5)', fontWeight: 'bold' }}
        color="gray.800"
        fontWeight="bold"
        borderTopRightRadius="0"
        borderBottomRightRadius="0"
        border="1px solid rgba(255,255,255,0.4)"
        _hover={{
          border: '1px solid rgba(255,255,255,1)',
        }}
        _focus={{
          border: '1px solid rgba(255,255,255,1)',
        }}
      />
      <IconButton
        backgroundColor="white"
        _hover={{ backgroundColor: 'rgba(255,255,255,0.75)' }}
        borderTopLeftRadius="0"
        borderBottomLeftRadius="0"
        aria-label="Search"
        icon={(
          <Icon
            as={FiSearch}
            color="brand.orange.400"
          />
)}
        onClick={handleSearch}
      />
    </Flex>
  );
}
