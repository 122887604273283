import { useState, useEffect } from 'react';
import { usePublicTransportKPI } from '@src/modules/performance/context/reports-api-hooks';
import TextWidget from './TextWidget';

function PublicTransportWidget({ ...props }) {
  const queryInfo = usePublicTransportKPI();
  const [value, setValue] = useState('--');
  const [colour, setColour] = useState('GREY');
  const [marker, setMarker] = useState('none');

  useEffect(() => {
    if (queryInfo?.data) {
      const { data: result } = queryInfo;
      setColour(result.colour);
      if (result.value === '--') {
        setValue('No data');
        setColour('GREY');
      } else {
        const formattedText = result.value.toLocaleString('en-NZ', { minimumFractionDigits: 1, maximumFractionDigits: 1 });
        setValue(`${formattedText}`);
      }
      setMarker(result.marker);
    }
  }, [queryInfo]);

  return (
    <TextWidget
      title="Public Transport and Total Mobility"
      value={value}
      lockYear={queryInfo?.data?.lockYear}
      subtext1="Passenger kms"
      subtext2={`(${queryInfo?.data?.averageYearRange} Annual Avg & Trend)`}
      link="/performance/dashboard/public-transport"
      colour={colour}
      marker={marker}
      loading={queryInfo.isLoading}
      fontSize={value !== 'No data' ? 48 : 32}
      {...props}
    />
  );
}

export default PublicTransportWidget;
