import { atom } from 'recoil';

/*
 When jumping between domains we need to know the users selected RCA (dbid)
 To do this we pass the dbid in the query string.
 This atom is used to store the dbid from the query string.
 */
const queryStringDatabaseIdState = atom({
  key: 'queryStringDatabaseId',
  default: null,
});

/*
 This atom is used to store the lock year from the query string.
*/
const queryStringLockYearState = atom({
  key: 'queryStringLockYear',
  default: null,
});

export {
  queryStringDatabaseIdState,
  queryStringLockYearState,
};
