import { useState, useRef } from 'react';
import {
  Box, Flex, Text, Container, Wrap,
} from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';

import { useSafetyKPI } from '@src/modules/performance/context/map-api-hooks';
import MainLayout from '@src/components/layouts/main';
import {
  useCrashDistribution,
  useCyclingMode,
  useRoadMode,
  useTotalCollectiveRisk,
  useTotalPopulation,
  useTotalVKTPersonalRisk, useWalkingMode,
} from '@src/modules/performance/context/reports-api-hooks';
import SLAreaLineChart from './components/selfloading/SLAreaLineChart';
import SLTornadoChart from './components/selfloading/SLTornadoChart';
import HeaderTrendIndicator from './components/selfloading/HeaderTrendIndicator';
import ReportCard from '../../components/ReportCard';
import Pagination from '../../components/Pagination';
import InfoContentCollapse from './components/information/InfoContentCollapse';

import html from './help/Safety.html';
import InfoScroller from './components/information/InfoScroller';

const subheader = 'Fatal and Serious Injuries';

const areaLineReportChartColours = [
  'blue', 'black', 'white', 'lightgrey',
];

function Safety() {
  const { pathname } = useLocation();
  const reportUrl = pathname.split('/').pop();
  const totalPopulation = useTotalPopulation();
  const totalVKTPersonalRisk = useTotalVKTPersonalRisk();
  const totalCollectiveRisk = useTotalCollectiveRisk();
  const roadMode = useRoadMode();
  const cyclingMode = useCyclingMode();
  const walkingMode = useWalkingMode();
  const crashDistributionOnrc = useCrashDistribution('onrc');
  const crashDistributionOnf = useCrashDistribution('onf');
  const safetyKpi = useSafetyKPI();
  // this controls the expansion of the help section
  const [index, setIndex] = useState(null);

  const helpSectionRef = useRef(null);
  // triggered when the user clicks on the help icon in the title
  const expandHelpSection = () => {
    setIndex(0);
  };
  return (
    <MainLayout backgroundColor="gray.50">
      <Container maxW="full">
        <Pagination currentReportUrl={reportUrl} />
        <ReportCard mt={2} pt={0}>
          <ReportCard.Header title="Safety" subtitle={subheader} info={<InfoScroller expandHelpSection={expandHelpSection} ref={helpSectionRef} />}>
            <Flex>
              <Box mr={3}>
                <HeaderTrendIndicator
                  queryInfo={safetyKpi}
                />
              </Box>
              <Box pr={15}>
                <Text fontWeight="bold" color="gray">
                  Fatal & Serious Injuries/Trend
                  <br />
                  <Text as="span" fontSize="xs" color="gray">
                    (
                    {safetyKpi.data.averageYearRange}
                    {' '}
                    Annual Avg)
                  </Text>
                </Text>
              </Box>
            </Flex>
          </ReportCard.Header>
          <ReportCard.Body>
            <Wrap spacing={4}>
              <Box>
                <Text mb={5} textStyle="reportSectionHeader">Network</Text>
                <Flex alignContent="start" className="gap">
                  {/* three charts */}
                  <SLAreaLineChart
                    queryInfo={totalPopulation}
                    title1="Total per 100,000 population"
                    title2="&nbsp;"
                    labels={[
                      'TA',
                      'Peer Group',
                      'top',
                      '25-75th National %ile',
                    ]}
                    colours={areaLineReportChartColours}
                    unitprefix=""
                    unitpostfix=""
                  />
                  <SLAreaLineChart
                    queryInfo={totalVKTPersonalRisk}
                    title1="Total per 100M VKT (personal risk)"
                    title2="&nbsp;"
                    labels={[
                      'TA',
                      'Peer Group',
                      'top',
                      '25-75th National %ile',
                    ]}
                    colours={areaLineReportChartColours}
                    unitprefix=""
                    unitpostfix=""
                  />
                  <SLAreaLineChart
                    queryInfo={totalCollectiveRisk}
                    title1="Total per 1,000km (collective risk)"
                    title2="&nbsp;"
                    labels={[
                      'TA',
                      'Peer Group',
                      'top',
                      '25-75th National %ile',
                    ]}
                    colours={areaLineReportChartColours}
                    unitprefix=""
                    unitpostfix=""
                  />
                </Flex>
              </Box>

              <Box>
                <Text mb={5} textStyle="reportSectionHeader">By Mode (no. per 100,000 Population)</Text>
                <Flex alignContent="start" className="gap">
                  {/* three charts */}
                  <SLAreaLineChart
                    queryInfo={roadMode}
                    title1="Road"
                    title2="&nbsp;"
                    labels={[
                      'TA',
                      'Peer Group',
                      'top',
                      '25-75th National %ile',
                    ]}
                    colours={areaLineReportChartColours}
                    unitprefix=""
                    unitpostfix=""
                  />
                  <SLAreaLineChart
                    queryInfo={cyclingMode}
                    title1="Cycling"
                    title2="&nbsp;"
                    labels={[
                      'TA',
                      'Peer Group',
                      'top',
                      '25-75th National %ile',
                    ]}
                    colours={areaLineReportChartColours}
                    unitprefix=""
                    unitpostfix=""
                  />
                  <SLAreaLineChart
                    queryInfo={walkingMode}
                    title1="Walking"
                    title2="&nbsp;"
                    labels={[
                      'TA',
                      'Peer Group',
                      'top',
                      '25-75th National %ile',
                    ]}
                    colours={areaLineReportChartColours}
                    unitprefix=""
                    unitpostfix=""
                  />
                </Flex>
              </Box>

              <Box>
                <Text mb={5} textStyle="reportSectionHeader">Crash Distribution</Text>
                <Flex alignContent="start" className="gap">
                  <SLTornadoChart
                    queryInfo={crashDistributionOnrc}
                    title1="Length vs no. of fatal and serious injuries (ONRC)"
                    title2="&nbsp;"
                    labels={[
                      'Length %',
                      'Fatal and serious %',
                    ]}
                    colours={['blue', 'red']}
                    unitprefix=""
                    unitpostfix="%"
                    precision={0}
                  />
                  <SLTornadoChart
                    queryInfo={crashDistributionOnf}
                    title1="Length vs no. of fatal and serious injuries (ONF)"
                    title2="&nbsp;"
                    labels={[
                      'Length %',
                      'Fatal and serious %',
                    ]}
                    colours={['blue', 'red']}
                    unitprefix=""
                    unitpostfix="%"
                    precision={0}
                  />
                </Flex>
              </Box>
            </Wrap>
          </ReportCard.Body>
        </ReportCard>
        <InfoContentCollapse
          ref={helpSectionRef}
          title="Information Sources"
          html={html}
          index={index}
          setIndex={setIndex}
        />
      </Container>
    </MainLayout>
  );
}

export default Safety;
