import { Image, Flex, Link } from '@chakra-ui/react';
import { NavLink as RouterLink } from 'react-router-dom';

import Logo from '@src/assets/images/transport-insights-logo-inverse.svg';

/**
 * @param {import('@chakra-ui/react').FlexProps} props
 */
export default function HeaderLogo(props) {
  return (
    <Flex align="center" {...props}>
      <Link as={RouterLink} to="/" border="none!important" p={0}>
        <Image src={Logo} height="60px" alt="Transport Insights" />
      </Link>
    </Flex>
  );
}
