import { useEffect, useState, useRef } from 'react';
import {
  Container,
  Box,
  Flex,
  Text,
  SimpleGrid,
  GridItem,
  Tag,
  Link,
  Stack,
  Switch,
  Tooltip,
  Heading,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  IconButton,
  List,
  ListItem,
  useDisclosure,
  useTheme,
} from '@chakra-ui/react';
import GaugeChart from 'react-gauge-chart';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { isEmpty } from 'lodash';
import { usePortalNavContext } from '@src/context/nav';
import { selectedDatabaseIdState } from '@src/state/navContext';
import { selectedFiltersState, selectedCategoryTypeState } from '@src/modules/onf/state/filtersContext';
import { getContrastColor } from '@src/utils/color';
import MainLayout from '@src/components/layouts/main';
import { useReports } from '@src/modules/onf/context/reports';
import LoadingSpinner from '@src/components/shared/LoadingSpinner';
import EmptyState from '@src/components/shared/EmptyState';
import { useNetworkSelector } from '@src/modules/onf/context/network-selector';
import { FiMoreHorizontal } from 'react-icons/fi';
import { reportsList } from '@src/modules/onf/api/reports-list';
import ReportHeading from '../components/shared/ReportHeading';
import ReportModal from '../components/shared/ReportModal';
import DataQualityTable from '../components/shared/DataQualityTable';
import NetworkSelector from '../components/shared/NetworkSelector';
import ReportSelector from '../components/shared/ReportSelector';
import MeasureDescription from '../components/shared/MeasureDescription';
import Pagination from '../components/shared/Pagination';
import ReportToggles from '../components/shared/ReportToggles';

import { categories } from '../api/categories';

function MoreDrawer({ children }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef();
  return (
    <>
      <Tooltip label="More Information">
        <IconButton ref={btnRef} onClick={onOpen} icon={<FiMoreHorizontal />} borderRadius="full" colorScheme="gray" ml={3} />
      </Tooltip>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
        size="lg"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader />
          <DrawerBody>
            {children}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default function ReportView() {
  const theme = useTheme();
  const rcaId = useRecoilValue(selectedDatabaseIdState);
  const { reportId } = useParams();
  const { isLoading } = usePortalNavContext();
  const filters = useRecoilValue(selectedFiltersState);
  const setFilters = useSetRecoilState(selectedFiltersState);
  const categoryType = useRecoilValue(selectedCategoryTypeState);
  const setCategoryType = useSetRecoilState(selectedCategoryTypeState);
  const [dataCategories, setDataCategories] = useState([]);
  const [params, setParams] = useState({ networks: [], hno: [] });
  const { data: rawData, isLoading: isLoadingReport, refetch } = useReports(reportId, params);
  const { data: selectorData, isLoading: isLoadingSelectorData } = useNetworkSelector();
  const { relevantLinks, importantLinks, toggles } = reportsList.find((x) => x.id === reportId) || undefined;
  const [selectedToggleOption, setSelectedToggleOption] = useState(Object.fromEntries(toggles?.map((x) => ([x.key, x.default]))));
  // Set global apex chart options
  window.Apex.chart = {
    toolbar: {
      show: true,
      tools: {
        download: true,
      },
    },
    zoom: {
      enabled: false,
    },
  };

  window.Apex.noData = {
    text: 'Selected categories have no totals for the year',
  };

  const handleQuickFilter = () => {
    switch (categoryType) {
      case 'all':
        setFilters({
          categories: categories
            .filter((x) => dataCategories.includes(x.description))
            .map((x) => x.description),
        });
        break;
      case 'urban':
        setFilters({
          categories: categories
            .filter((x) => dataCategories.includes(x.description))
            .filter((x) => x.streetFamily === 'Urban')
            .map((x) => x.description),
        });
        break;
      case 'rural':
        setFilters({
          categories: categories
            .filter((x) => dataCategories.includes(x.description))
            .filter((x) => x.streetFamily === 'Rural')
            .map((x) => x.description),
        });
        break;
      default:
        break;
    }
  };

  const isCatDisabled = (catName) => (!dataCategories.includes(catName));

  const handleSetCategory = (catName) => {
    if (!isCatDisabled(catName)) {
      const updatedCategories = filters.categories.includes(catName) ? filters.categories.filter((x) => x !== catName) : [...filters.categories, catName];
      setFilters({ ...filters, categories: updatedCategories });
    }
  };

  useEffect(() => {
    const updatedCategories = filters.categories;
    const withStreetFamily = updatedCategories.map((x) => categories.find((y) => y.description === x));

    if (!isLoading && dataCategories.length > 0) {
      if (updatedCategories.length === dataCategories.length) {
        setCategoryType('all');
      } else if (withStreetFamily.every((x) => x.streetFamily === 'Urban') && withStreetFamily.length === categories.filter((x) => x.streetFamily === 'Urban').length) {
        setCategoryType('urban');
      } else if (withStreetFamily.every((x) => x.streetFamily === 'Rural') && withStreetFamily.length === categories.filter((x) => x.streetFamily === 'Rural').length) {
        setCategoryType('rural');
      } else {
        setCategoryType('');
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.categories, dataCategories, isLoading]);

  useEffect(() => {
    handleQuickFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryType]);

  useEffect(() => {
    setParams({ networks: [], hno: [] });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rcaId]);

  useEffect(() => {
    if (!isEmpty(rawData)) {
      setDataCategories([...new Set(rawData.data.map((x) => x.category))]);
    }
  }, [rawData]);

  useEffect(() => {
    if (filters.categories.length === 0 && categoryType === '') {
      setCategoryType('all');
    }
    if (dataCategories.length > 0) {
      handleQuickFilter();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataCategories]);

  const filteredData = isEmpty(rawData) ? {} : {
    ...rawData,
    data: rawData.data.sort((a, b) => a.rank - b.rank).filter((x) => filters.categories.indexOf(x.category) > -1),
  };

  const handleApplyCompareNetworks = (val) => {
    const networks = val.filter((x) => typeof x === 'number');
    const hno = val.filter((x) => typeof x === 'object');
    setParams({ ...params, networks, hno });
    refetch();
  };

  useEffect(() => {
    if (toggles?.length > 0 && !isLoadingReport) {
      setSelectedToggleOption(Object.fromEntries(toggles.map((x) => ([x.key, x.default]))));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawData]);

  const handleToggleOption = (key, val, isMulti) => {
    setSelectedToggleOption((prevState) => ({ ...prevState, [key]: isMulti ? val : [val] }));
  };

  if (isLoading || isLoadingReport || isEmpty(rawData)) {
    return (
      <MainLayout>
        <Container maxW="full">
          <LoadingSpinner />
        </Container>
      </MainLayout>
    );
  }

  return (
    <MainLayout>
      <Container maxW="full">
        <Box
          pt={2}
          pb={4}
          position="sticky"
          top="80px"
          background="white"
          zIndex="200"
        >
          <Flex align="center" justify="space-between">
            <ReportHeading reportId={reportId}>
              <Heading fontSize="3xl" m={0}>
                {rawData.reportName}
              </Heading>
            </ReportHeading>
            <Flex align="center">
              <Pagination currentReportId={reportId} reportData={rawData} />
              <MoreDrawer>
                {relevantLinks?.length > 0
                  && (
                  <Box mb={4}>
                    <Heading as="h3" fontSize="lg" mb={2}>Relevant Links</Heading>
                    {relevantLinks.map((link) => (
                      <Link
                        as={RouterLink}
                        to={link.url}
                        fontSize="md"
                        key={link.url}
                        display="block"
                        py={1}
                      >
                        {link.title}
                      </Link>
                    ))}
                  </Box>
                  )}
                <ReportModal title="Measure Description" mt={2}>
                  <MeasureDescription reportId={reportId} />
                </ReportModal>
                {importantLinks?.length > 0
                  && (
                  <ReportModal title="Important Links" size="lg" mt={2}>
                    <List mb={3} spacing={3}>
                      {importantLinks.map((link, idx) => (
                        <ListItem key={`link_${idx}`}>
                          <Link href={link.url} target={link.isExternal ? '_blank' : '_self'}>
                            {' '}
                            {link.title}
                          </Link>
                        </ListItem>
                      ))}
                    </List>
                  </ReportModal>
                  )}
                {rawData.rcas?.filter((rca) => rca.id === parseInt(rcaId, 10)).length === 1 && rawData.rcas?.filter((rca) => rca.dataConfidence).length === 1
                  && (
                  <ReportModal title="Data Quality" size="full" mt={2}>
                    <DataQualityTable
                      reportId={reportId}
                    />
                  </ReportModal>
                  )}
                {Number.parseInt(rcaId, 10) < 1000 && rawData.rcas?.some((rca) => rca.dataConfidence)
                  && (
                  <Box>
                    <Heading as="h3" fontSize="lg" my={4}>Data Confidence</Heading>
                    <SimpleGrid minChildWidth={['100%', '45%']} spacing={2}>
                      {rawData.rcas?.filter((x) => x.dataConfidence).map((x) => (
                        <GridItem key={`rca_${x.rcaName}`} p={2} background="gray.50" borderRadius="md">
                          <Flex alignSelf="flex-start">
                            <Flex direction="row" alignItems="center">
                              <GaugeChart
                                nrOfLevels={4}
                                arcPadding={0.025}
                                cornerRadius={2}
                                percent={x.dataConfidence / 100}
                                colors={['#cccccc', '#999999', '#666666', '#333333']}
                                hideText
                                style={{ width: 150 }}
                              />
                              <Box>
                                <Text fontWeight="bold" fontSize="sm" lineHeight="1" mb={1}>{x.rcaName}</Text>
                                <Text fontWeight="normal" fontSize="sm">
                                  {x.dataConfidence}
                                  %
                                </Text>
                              </Box>
                            </Flex>
                          </Flex>
                        </GridItem>
                      ))}
                    </SimpleGrid>
                  </Box>
                  )}
              </MoreDrawer>
            </Flex>
          </Flex>
        </Box>
        {isEmpty(rawData?.data)
        && (
          <EmptyState title="No Data" message="Sorry there is no data available for selected network" />
        )}
        {!isEmpty(rawData?.data)
        && (
        <>
          <Box
            pt={4}
            position="sticky"
            top="150px"
            background="white"
            zIndex="200"
          >
            <Box
              p={4}
              background="white"
              border="1px solid"
              borderColor="gray.100"
              borderRadius="md"
              boxShadow="sm"
            >
              {toggles?.length > 0 && toggles.map((toggle) => (
                <ReportToggles key={toggle.key} options={toggle.options} text={toggle.text} isMulti={toggle.isMulti} onSelectToggle={(value) => handleToggleOption(toggle.key, value, toggle.isMulti)} />
              ))}
              <Box>
                <Flex align="center" justify="space-between">
                  <Text fontWeight="bold" fontSize="lg">Filter by Category:</Text>
                  <Stack direction="row">
                    <Switch value="all" onChange={(e) => setCategoryType(e.currentTarget.value)} isChecked={categoryType === 'all' || filters.categories.length === 12} size="sm">All Categories</Switch>
                    <Switch value="urban" onChange={(e) => setCategoryType(e.currentTarget.value)} isChecked={categoryType === 'urban'} size="sm">Urban</Switch>
                    <Switch value="rural" onChange={(e) => setCategoryType(e.currentTarget.value)} isChecked={categoryType === 'rural'} size="sm">Rural</Switch>
                  </Stack>
                </Flex>
                <Flex mt={4} direction="row" wrap="wrap">
                  {categories.map(({ description: catName }) => (
                    <Tooltip key={catName} hasArrow label="Not used by the network" isDisabled={!isCatDisabled(catName)}>
                      <Tag
                        borderRadius="full"
                        variant="solid"
                        my={1}
                        mr={2}
                        color={isCatDisabled(catName) ? 'gray.600' : getContrastColor(theme.colors.onfCategory[catName].base)}
                        backgroundColor={isCatDisabled(catName) ? 'gray.200' : theme.colors.onfCategory[catName].base}
                        opacity={filters.categories.find((x) => x === catName) ? 1 : 0.4}
                        onClick={() => {
                          handleSetCategory(catName);
                        }}
                        cursor="pointer"
                      >
                        {catName}
                      </Tag>
                    </Tooltip>
                  ))}
                </Flex>
              </Box>
            </Box>
          </Box>
          {filters.categories.length > 0 && filteredData.data?.length > 0
          && (
          <>
            <ReportSelector
              reportId={reportId}
              filters={filters}
              selectedToggleOption={selectedToggleOption}
              reportData={filteredData}
              networkSelector={(
                <NetworkSelector
                  currentNetworks={rawData.reportParams.networks.length > 0
                    ? rawData.reportParams.networks.map((x) => parseInt(x, 10))
                    : rawData.rcas.filter((x) => x.id !== parseInt(rcaId, 10)).map((x) => x.id)}
                  currentHnos={rawData.reportParams.hno.length > 0 ? rawData.reportParams.hno : []}
                  lockYear={rawData.lockYear}
                  data={selectorData}
                  isLoading={isLoadingSelectorData || isLoadingReport}
                  callback={(val) => handleApplyCompareNetworks(val)}
                />
              )}
            />
            <Text mt={4} fontSize="sm">
              Regional and National results include Local Authorities and State Highways.
              <br />
              Local Authority results and Peer Group results do not include State Highways.
            </Text>
          </>
          )}
          {filters.categories.length > 0 && filteredData.data?.length === 0 && !isLoadingReport
          && <EmptyState title="No Data" message="Sorry there is no data available for selected categories" />}
          {filters.categories.length === 0 && filteredData.data?.length === 0 && !isLoadingReport
          && (
          <Box my={4} py={8} textAlign="center" borderRadius="md" border="1px solid">
            <Text>Please select at least 1 category</Text>
          </Box>
          )}
        </>
        )}
      </Container>
    </MainLayout>
  );
}
