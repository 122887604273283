import { Spacer } from '@chakra-ui/react';
import UserMenu from '@src/components/layouts/header/components/UserMenu';
import { useUserInfo } from '@transport-insights/uikit';
import SignIn from '@src/components/layouts/header/components/SignIn';
import MainHeaderLayout from './MainHeaderLayout';
import NavContextSwitcher from './components/NavContextSwitcher';
import SearchBar from './components/SearchBar';

export default function MainHeader() {
  const { isLoggedIn } = useUserInfo();
  return (
    <MainHeaderLayout>
      <NavContextSwitcher />
      <Spacer />
      <SearchBar />
      {isLoggedIn ? <UserMenu /> : <SignIn />}
    </MainHeaderLayout>
  );
}
