import {
  Flex, Center,
} from '@chakra-ui/react';

function LockYearBadge({ lockYear }) {
  if (!lockYear) {
    return null;
  }
  return (
    <Center>
      <Flex
        border="1px solid"
        borderColor="gray.200"
        color="gray.600"
        fontSize="12px"
        textAlign="center"
        fontWeight="bold"
        py="1px"
        px={2}
        borderRadius="full"
        flexDirection="row"
        align="center"
        cursor="default"
      >
        {lockYear}
      </Flex>
    </Center>
  );
}

export default LockYearBadge;
