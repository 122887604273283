import {
  Flex,
  Spinner,
} from '@chakra-ui/react';

export default function LoadingSpinner({ ...props }) {
  return (
    <Flex align="center" justify="center" width="100%" height="calc(100vh - 80px)" {...props}>
      <Spinner color="brand.orange.400" size="xl" />
    </Flex>
  );
}
