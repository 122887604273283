import { useState, useEffect } from 'react';
import TextWidget from './TextWidget';

function SLTextWidget({
  queryInfo, colour, title, subtext1, subtext2, link, prefix, postfix, precision, fieldName, ...props
}) {
  const [text, setText] = useState('--');
  const [fontSize, setFontSize] = useState(48);
  const [textColour, setTextColour] = useState(colour);

  useEffect(() => {
    if (queryInfo?.data) {
      const { data: result } = queryInfo;
      if (result.nodata) {
        setText('No data');
        setFontSize(32);
        setTextColour('GREY');
      } else {
        const f = fieldName || 'value';
        if (result[f]) {
          let formatPrecision = 1;
          if (precision !== null) {
            formatPrecision = precision;
          }
          setText(`${prefix || ''}${result[f].toFixed(formatPrecision)}${postfix || ''}`);
          setTextColour(colour);
          setFontSize(48);
        } else {
          setText('No data');
          setFontSize(32);
          setTextColour('GREY');
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryInfo]);
  return (
    <TextWidget
      title={title}
      value={text}
      lockYear={queryInfo?.data?.lockYear}
      subtext1={subtext1}
      subtext2={subtext2}
      loading={queryInfo.isLoading}
      link={link}
      colour={textColour}
      marker="none"
      fontSize={fontSize}
      {...props}
    />
  );
}

export default SLTextWidget;
