import {
  Box, Divider, Flex, Link, Text,
} from '@chakra-ui/react';
import Card from '@src/modules/performance/components/Card';
import PlaceHolder from '@src/modules/performance/components/Placeholder';
import {
  isEmpty, isNumber, last, reduce,
} from 'lodash';
import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';

import { useCyclewaysNetworkLength, useDeliveryKPI, useStats } from '@src/modules/performance/context/reports-api-hooks';

// NOTE:

// renders a list of network characteristics, which will vary by council
// some have only a few, some have lots
function RCAStatsWidget() {
  const { data, isLoading, isError } = useStats();
  const { data: deliveryData } = useDeliveryKPI();
  const { data: cyclewayData } = useCyclewaysNetworkLength();

  const formatNumber = (value, digits, prefix, postfix, noDataValue) => {
    if (value !== null && value !== '') {
      return `${prefix || ''}${value.toLocaleString('en-NZ', { minimumFractionDigits: digits, maximumFractionDigits: digits })}${postfix || ''}`;
    }
    if (noDataValue !== null) {
      return noDataValue;
    }
    return 'N/A';
  };

  return (
    <>
      <Card width="100%" p={3}>
        <Box width="100%">
          {(isLoading || isError)
            // placeholder thingee - there are 12 values
            && Array.from(Array(12), (e, i) => (
              <React.Fragment key={`kpi-${i}`}>
                <Box>
                  <PlaceHolder height={4} width={120} mb={2} />
                  <PlaceHolder height={4} width={120} />
                </Box>
                <Divider mt={2} mb={2} />
              </React.Fragment>
            ))}
          {(!isLoading && !isError && data)
            && (
              <>
                <Box key="kpi-pop">
                  <Text textStyle="summaryValue">{formatNumber(data.Population.Value, 0, null, null, 0)}</Text>
                  <Flex justify="space-between">
                    <Text textStyle="summaryTitle">Population</Text>
                    <Text textStyle="summaryTitle">{data.Population.LockYear}</Text>
                  </Flex>
                </Box>
                <Divider mt={2} mb={2} />
                <Box key="kpi-gdp">
                  <Text textStyle="summaryValue">{formatNumber(data.GDP.Value, 0, '$', null, 'No data')}</Text>
                  <Flex justify="space-between">
                    <Text textStyle="summaryTitle">GDP ($M)</Text>
                    <Text textStyle="summaryTitle">{data.GDP.LockYear}</Text>
                  </Flex>
                </Box>
                <Divider mt={2} mb={2} />
                <Box key="kpi-budget">
                  <Text textStyle="summaryValue">{isNumber(deliveryData?.budget) ? formatNumber(deliveryData?.budget, 1, '$', null, 'No data') : 'No data'}</Text>
                  <Flex justify="space-between">
                    <Text textStyle="summaryTitle">Budget ($M)</Text>
                    <Text textStyle="summaryTitle">{deliveryData?.lockYear}</Text>
                  </Flex>
                </Box>
                <Divider mt={2} mb={2} />
                <Box key="kpi-val">
                  <Text textStyle="summaryValue">{formatNumber(data.Valuation.Value, 0, '$', null, 0)}</Text>
                  <Flex justify="space-between">
                    <Text textStyle="summaryTitle">Valuation ($M)</Text>
                    <Text textStyle="summaryTitle">{data.Valuation.ReportYear}</Text>
                  </Flex>
                </Box>
                <Divider mt={2} mb={2} />
                <Box key="kpi-exp">
                  <Text textStyle="summaryValue">{formatNumber(data.Expenditure.Value, 1, '$', null, 0)}</Text>
                  <Flex justify="space-between">
                    <Text textStyle="summaryTitle">Expenditure ($M)</Text>
                    <Text textStyle="summaryTitle">{data.Expenditure.LockYear}</Text>
                  </Flex>
                </Box>
                <Divider mt={2} mb={2} />
                <Box key="kpi-cap">
                  <Text textStyle="summaryValue">{formatNumber(data.ExpenditurePerCapita.Value, 0, '$', null, 0)}</Text>
                  <Flex justify="space-between">
                    <Text textStyle="summaryTitle">Expenditure per capita</Text>
                    <Text textStyle="summaryTitle">{data.ExpenditurePerCapita.LockYear}</Text>
                  </Flex>
                </Box>
                <Divider mt={2} mb={2} />
                <Box key="kpi-tot-rd">
                  <Text textStyle="summaryValue">{formatNumber(data.TotalKm.Value, 0, null, null, 0)}</Text>
                  <Flex justify="space-between">
                    <Text textStyle="summaryTitle">Total Roads (km)</Text>
                    <Text textStyle="summaryTitle">{data.TotalKm.LockYear}</Text>
                  </Flex>
                </Box>
                <Divider mt={2} mb={2} />
                <Box key="kpi-tot-cyc">
                  <Text textStyle="summaryValue">{!isEmpty(cyclewayData?.values) ? reduce(cyclewayData?.values, (acc, v) => acc + last(v), 0).toFixed(1) : 'No data'}</Text>
                  <Flex justify="space-between">
                    <Text textStyle="summaryTitle">Total Cycleways (km)</Text>
                    <Text textStyle="summaryTitle">{cyclewayData?.lockYear}</Text>
                  </Flex>
                </Box>
                <Divider mt={2} mb={2} />
                <Box key="kpi-brid">
                  <Text textStyle="summaryValue">{formatNumber(data.BridgesCount.Value, 0, null, null, 0)}</Text>
                  <Flex justify="space-between">
                    <Text textStyle="summaryTitle">No. of bridges</Text>
                    <Text textStyle="summaryTitle">{data.BridgesCount.LockYear}</Text>
                  </Flex>
                </Box>
                <Divider mt={2} mb={2} />
                <Box key="kpi-far">
                  <Text textStyle="summaryValue">{formatNumber(data.FAR.Value * 100.0, 0, null, '%', 0)}</Text>
                  <Flex justify="space-between">
                    <Text textStyle="summaryTitle">FAR</Text>
                    <Text textStyle="summaryTitle">{data.FAR.LockYear}</Text>
                  </Flex>
                </Box>
              </>
            )}
        </Box>
      </Card>
      {!isLoading && (
        <Box mt={4} textAlign="center">
          <Link
            as={ReactRouterLink}
            to="/performance/network-characteristics"
            fontSize="xs"
            display="block"
            p={2}
            border="1px solid"
            borderColor="gray.100"
            borderRadius="md"
            textDecoration="none"
            mb={2}
            _hover={{
              borderColor: 'blue.200',
            }}
          >
            ONRC Network Characteristics
          </Link>
          <Link
            as={ReactRouterLink}
            to="/onf/network-characteristics"
            fontSize="xs"
            display="block"
            p={2}
            border="1px solid"
            borderColor="gray.100"
            borderRadius="md"
            textDecoration="none"
            _hover={{
              borderColor: 'blue.200',
            }}
          >
            ONF Network Characteristics
          </Link>
        </Box>
      )}
    </>
  );
}

export default RCAStatsWidget;
