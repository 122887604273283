import { useState, useEffect } from 'react';
import { useAchievementsKPI } from '@src/modules/performance/context/map-api-hooks';
import TextWidget from '@src/modules/performance/views/reports/components/dashboard/TextWidget';

function AmenityConditionWidget({ ...props }) {
  const { data: achievement } = useAchievementsKPI();
  const [value, setValue] = useState('--');
  const [colour, setColour] = useState('GREY');
  const [marker, setMarker] = useState('none');

  useEffect(() => {
    setColour(achievement.colour);
    setValue(achievement.value || 'No data');
    setMarker(achievement.marker);
  }, [achievement]);

  return (
    <TextWidget
      title="Amenity and Condition"
      value={value}
      lockYear={achievement.lockYear}
      subtext1="Ride Quality STE"
      link="/performance/dashboard/amenity-and-condition"
      colour={colour}
      marker={marker}
      fontSize={value !== 'No data' ? 48 : 32}
      {...props}
    />
  );
}

export default AmenityConditionWidget;
