import {
  Text, Divider, LinkBox, LinkOverlay, Flex, Box, Center, Icon,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import Card from '@src/modules/performance/components/Card';

import { FiArrowRight } from 'react-icons/fi';
import TrafficLight from '../indicators/TrafficLight';

function TrafficLightWidget({
  title, subtext1, subtext2, link, colour, ...rest
}) {
  return (
    <Card
      width="100%"
      p={0}
      borderWidth="1px"
      borderColor="white"
      cursor="pointer"
      _hover={{ borderColor: 'brand.orange.400' }}
      {...rest}
    >
      <LinkBox
        p={2}
        height="100%"
        display="flex"
        justify="space-between"
        flexDirection="column"
      >
        <Box height={50}>
          <Center>
            <Box height={5} width={190}>
              <Text textStyle="cardHeader">
                {title}
              </Text>
            </Box>
          </Center>
        </Box>
        <Box align="center" height={110}>
          {/* <PlaceholderCircle size="110"> */}
          <TrafficLight colour={colour} size="110px" />
          {/* </PlaceholderCircle> */}
        </Box>
        <Box height={50} pt={5}>
          <Center>
            <Box height={5} width={190}>
              <Text textStyle="cardSubtext">
                {subtext1}
              </Text>
              <Text textStyle="cardSubtext">
                {subtext2}
              </Text>
            </Box>
          </Center>
        </Box>
        <Flex flexGrow="1" pt={2} direction="column" justify="flex-end" align="flex-end">
          <Divider />
          <LinkOverlay mt={2} as={RouterLink} to={link} display="inline-flex"><Icon as={FiArrowRight} boxSize={6} /></LinkOverlay>
        </Flex>
      </LinkBox>
    </Card>
  );
}

export default TrafficLightWidget;
