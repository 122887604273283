import {
  Container,
  Flex,
} from '@chakra-ui/react';
import MainLayout from '@src/components/layouts/main';
import {
  useInvestmentAudit, useInvestmentAuditKPI,
  useTechnicalAudit, useTechnicalAuditKPI,
} from '@src/modules/performance/context/reports-api-hooks';
import { useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Pagination from '../../components/Pagination';
import ReportCard from '../../components/ReportCard';
import InfoContentCollapse from './components/information/InfoContentCollapse';
import InfoScroller from './components/information/InfoScroller';
import Legend from './components/information/Legend';
import HeaderCoinvestorTrafficLight from './components/selfloading/HeaderCoInvestorTrafficLight';
import SmallTrafficLightTable from './components/selfloading/SmallTrafficLightTable';
import html from './help/Co-InvestorAssurance.html';

const subheader = 'Investment Performance';

const mapValueToText = (value) => {
  const text = [
    'Not Available', // blank/grey 0.0
    'Unacceptable', // red        0.25
    'Significant improvement needed', // orange     0.5
    'Some improvement needed', // yellow     0.75
    'Effective', // green      1.00
  ];
  return text[value * 4];
};

const mapColour = (value) => {
  const colours = ['GREY', 'RED', 'ORANGE', 'YELLOW', 'GREEN'];
  return colours[value * 4];
};

// Convert the date string in the format MMM-YY to a financial year
function convertToFinancialYear(dateString) {
  if (!dateString) {
    return '';
  }
  // Split the date string into month and year
  const splitDate = dateString?.split('-');
  // Convert the month to a number
  const month = new Date(`${splitDate[0]} 01 2000`).toLocaleDateString('en', { month: '2-digit' });
  // Get the full year
  const year = parseInt(splitDate[1], 10) + 2000;
  // Create a date object
  const fullDate = new Date(year, month - 1, 1);

  // Determine the financial year start date (July to June)
  const financialYearStart = new Date(year, 6, 1);
  // Compare dates and return the appropriate financial year
  const financialYear = (fullDate >= financialYearStart) ? `${year}/${year - 1999}` : `${year - 1}/${year - 2000}`;

  return `(${financialYear})`;
}

function CoInvestorAssurance() {
  const { pathname } = useLocation();
  const reportUrl = pathname.split('/').pop();
  const investmentAudit = useInvestmentAudit();
  const technicalAudit = useTechnicalAudit();
  const investmentAuditKPI = useInvestmentAuditKPI();
  const technicalAuditKPI = useTechnicalAuditKPI();
  const [grades, setGrades] = useState([]);
  // this controls the expansion of the help section
  const [index, setIndex] = useState(null);

  const helpSectionRef = useRef(null);
  // triggered when the user clicks on the help icon in the title
  const expandHelpSection = () => {
    setIndex(0);
  };

  // tables passes up loaded data which tells us if the data has a grade of 3 or 4
  const setGrading = (data) => {
    if (data && data.grading !== 0) {
      setGrades((old) => [...old, data.grading]);
    }
  };

  return (
    <MainLayout backgroundColor="gray.50">
      <Container maxW="full">
        <Pagination currentReportUrl={reportUrl} />
        <ReportCard mt={2} pt={0}>
          <ReportCard.Header title="Co-Investor Assurance" subtitle={subheader} info={<InfoScroller expandHelpSection={expandHelpSection} ref={helpSectionRef} />}>
            <Flex>
              <HeaderCoinvestorTrafficLight
                size="65px"
                queryInfo={investmentAuditKPI}
                mapText={mapValueToText}
                mapColour={mapColour}
                title={`Investment\n${convertToFinancialYear(investmentAudit.data?.date)}`}
                alttitle=""
                isTechnical={false}
              />
              <HeaderCoinvestorTrafficLight
                size="65px"
                queryInfo={technicalAuditKPI}
                mapText={mapValueToText}
                mapColour={mapColour}
                title={`Technical\n${convertToFinancialYear(technicalAudit.data?.date)}`}
                alttitle={`Overall Rating\n${convertToFinancialYear(technicalAudit.data?.date)}`}
                isTechnical
              />
            </Flex>
          </ReportCard.Header>
          <ReportCard.Body>
            <Flex mb={10}>
              <SmallTrafficLightTable queryInfo={investmentAudit} onDataLoad={setGrading} sourceText="Source: Waka Kotahi, Audit and Assurance, Investment Audit Report" />
              <SmallTrafficLightTable queryInfo={technicalAudit} onDataLoad={setGrading} sourceText="Source: Waka Kotahi, Audit and Assurance, Technical Audit Report" />
            </Flex>
            <Flex>
              {grades.indexOf(3) > -1 && (
              <Legend
                title="Three Grades"
                values={['Effective', 'Improvement needed', 'Unacceptable', 'Not Available']}
                colours={['GREEN', 'ORANGE', 'RED', 'GREY']}
                vertical
              />
              )}
              {grades.indexOf(4) > -1 && (
              <Legend
                title="Four Grades"
                values={['Effective', 'Some improvement needed', 'Significant improvement needed', 'Unacceptable', 'Not Available']}
                colours={['GREEN', 'YELLOW', 'ORANGE', 'RED', 'GREY']}
                vertical
              />
              )}
            </Flex>
          </ReportCard.Body>
        </ReportCard>
        <InfoContentCollapse
          ref={helpSectionRef}
          title="Information Sources"
          html={html}
          index={index}
          setIndex={setIndex}
        />
      </Container>
    </MainLayout>
  );
}

export default CoInvestorAssurance;
