import { queryStringDatabaseIdState, queryStringLockYearState } from '@src/state/queryString';
import qs from 'qs';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

/*
This component is responsible for setting the selected database id + lock year from the query string
We then remove the query string from the URL to keep it looking clean
*/
export default function QueryStringParser({ children }) {
  const setSelectedDatabaseId = useSetRecoilState(queryStringDatabaseIdState);
  const setSelectedLockYear = useSetRecoilState(queryStringLockYearState);

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (!location || !history || !setSelectedDatabaseId || !setSelectedLockYear) {
      return;
    }

    const { dbid, lockyear } = qs.parse(location.search || '', { ignoreQueryPrefix: true });
    if (!dbid && !lockyear) {
      return;
    }

    if (dbid) {
      setSelectedDatabaseId(dbid);
    }
    if (lockyear) {
      setSelectedLockYear(lockyear);
    }

    const searchParams = new URLSearchParams(location.search);
    searchParams.delete('dbid');
    searchParams.delete('lockyear');

    const newLocation = {
      ...location,
      search: searchParams.toString(),
    };
    history.replace(newLocation);
  }, [
    history,
    location,
    setSelectedDatabaseId,
    setSelectedLockYear,
  ]);

  return children;
}
