import {
  Text, Divider, LinkBox, LinkOverlay, Flex, Box, Center, Icon,
} from '@chakra-ui/react';
import Card from '@src/modules/performance/components/Card';
import { Pie } from 'react-chartjs-2';
import PlaceHolder from '@src/modules/performance/components/Placeholder';
import PlaceholderCircle from '@src/modules/performance/components/PlaceholderCircle';
import { Link as RouterLink } from 'react-router-dom';
import LockYearBadge from '@src/modules/performance/components/LockYearBadge';
import { FiArrowRight } from 'react-icons/fi';

function PieChartWidget({
  title, values, colours, subtext, link, loading, lockYear, ...rest
}) {
  const height = 90;
  const width = 90;

  const data = {
    labels: [],
    datasets: [
      {
        data: values, // this ensures the white part is shown
        backgroundColor: colours,
        borderWidth: 1, // this will hide white border line
      },
    ],
  };
  const options = {
    responsive: false,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        enabled: false,
      },
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
      },
    },
  };

  return (
    <Card
      width="100%"
      p={0}
      borderWidth="1px"
      borderColor="white"
      cursor="pointer"
      _hover={{ borderColor: 'brand.orange.400' }}
      {...rest}
    >
      <LinkBox
        p={2}
        height="100%"
        display="flex"
        justify="space-between"
        flexDirection="column"
      >
        <Box height={50}>
          <Center>
            <PlaceHolder isLoaded={!loading} height={5} width={190}>
              <Text textStyle="cardHeader">
                {title}
              </Text>
            </PlaceHolder>
          </Center>
        </Box>
        <Box align="center" height={110}>
          <PlaceholderCircle size="110" isLoaded={!loading}>
            <Pie data={data} options={options} height={height} width={width} mt={5} />
          </PlaceholderCircle>
        </Box>
        <Box height={50} pt={5}>
          <Center>
            <PlaceHolder isLoaded={!loading} height={5} width={190}>
              <Text textStyle="cardSubtext">
                {subtext}
              </Text>
            </PlaceHolder>
          </Center>
        </Box>
        <Flex flexGrow="1" pt={2} direction="column" justify="flex-end">
          <Divider />
          <Flex justify="space-between" align="center" pt={2}>
            <LockYearBadge lockYear={lockYear} />
            <LinkOverlay as={RouterLink} to={link} display="inline-flex"><Icon as={FiArrowRight} boxSize={6} /></LinkOverlay>
          </Flex>
        </Flex>
      </LinkBox>
    </Card>
  );
}

export default PieChartWidget;
