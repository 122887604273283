import { useQuery, useMutation, useQueryClient } from 'react-query';
import { currentDatabaseSelector, selectedLockYearState } from '@src/state/navContext';
import { useRecoilValue } from 'recoil';
import useTceApi from '../api/useTceApi';

const getQueryKey = (rca, lockyear) => ['tce-trafficgroups', rca, lockyear];

export function useTceTrafficGroups(providedLockYear = null) {
  const api = useTceApi();
  const rca = useRecoilValue(currentDatabaseSelector);
  const defaultLockYear = useRecoilValue(selectedLockYearState);

  const lockyear = providedLockYear ?? defaultLockYear;

  const { isLoading, data: trafficgroups, error } = useQuery(
    getQueryKey(rca, lockyear),
    async () => api.getTrafficGroups(rca?.id, lockyear),
    {
      enabled: lockyear !== null,
    },
  );

  return {
    isLoading,
    trafficgroups,
    error,
  };
}

// if submission contains an id, it's an update
// if it does not, it's a create
export function useCreateUpdateTrafficGroupMutation(mutationOptions = undefined) {
  const rca = useRecoilValue(currentDatabaseSelector);
  const lockyear = useRecoilValue(selectedLockYearState);
  const api = useTceApi();

  const queryClient = useQueryClient();
  return useMutation({
    ...mutationOptions,
    mutationFn: (submission) => api.createUpdateTrafficGroup(submission),
    onSuccess: (result) => {
      queryClient.setQueryData(
        getQueryKey(rca, lockyear),
        (oldData) => {
          if (!oldData) return [];
          const filteredData = oldData?.filter((t) => t.id !== result.id);
          filteredData.push(result);
          return filteredData;
        },
      );
      return mutationOptions?.onSuccess?.(result);
    },
  });
}

export function useDeleteTrafficGroupMutation(mutationOptions) {
  const api = useTceApi();
  const rca = useRecoilValue(currentDatabaseSelector);
  const lockyear = useRecoilValue(selectedLockYearState);

  const queryClient = useQueryClient();
  return useMutation({
    ...mutationOptions,
    mutationFn: (group) => api.deleteTrafficGroup(group.id),
    onSuccess: (result, group) => {
      queryClient.setQueryData(
        rca && getQueryKey(rca, lockyear),
        (oldData) => oldData.filter((t) => t.id !== group.id),
      );
      return mutationOptions?.onSuccess?.(group);
    },
  });
}
