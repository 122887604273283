import {
  Checkbox,
  Select,
  VStack,
} from '@chakra-ui/react';
import MainLayout from '@src/components/layouts/main/MainLayout';
import { selectedDatabaseIdState } from '@src/state/navContext';
import 'mapbox-gl/dist/mapbox-gl.css';
import {
  useCallback, useEffect,
  useRef,
  useState,
} from 'react';
import {
  Layer, Map, Source,
} from 'react-map-gl';
import { useRecoilValue } from 'recoil';
import Card from '../../components/Card';
import { COUNCILS_WITH_COORDINATES } from './councils';
import {
  clusterCountLayer, clusterLayer,
  onrcLayer,
  unclusteredPointLayer,
} from './layers';

export default function MapPOC() {
  const rcaId = useRecoilValue(selectedDatabaseIdState);
  const mapRef = useRef(null);

  const [useDarkMode, setUseDarkMode] = useState(true);

  const [showCrashes, setShowCrashes] = useState(true);
  const [hoveredCrash, setHoveredCrash] = useState(null);

  const [showOnrc, setShowOnrc] = useState(true);
  const [hoveredOnrc, setHoveredOnrc] = useState(null);

  const onHover = useCallback((event) => {
    const {
      features,
      point: { x, y },
    } = event;
    const hoveredFeature = features && features[0];

    if (hoveredFeature && hoveredFeature.source === 'onrc') {
      setHoveredOnrc(hoveredFeature && { feature: hoveredFeature, x, y });
    } else if (hoveredFeature && hoveredFeature.source === 'crashes') {
      setHoveredCrash(hoveredFeature && { feature: hoveredFeature, x, y });
    } else {
      setHoveredOnrc(null);
      setHoveredCrash(null);
    }
  }, []);

  const zoomToCity = useCallback(() => {
    const city = COUNCILS_WITH_COORDINATES.find((x) => x.id === rcaId);
    if (city && mapRef?.current) {
      const latitude = city.coordinates[1];
      const longitude = city.coordinates[0];
      mapRef.current?.flyTo({ center: [longitude, latitude], zoom: 12, duration: 3000 });
    }
  }, [rcaId]);

  useEffect(() => {
    zoomToCity();
  }, [rcaId, zoomToCity]);

  useEffect(() => {
    setTimeout(() => {
      zoomToCity();
    }, 2000);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClick = (event) => {
    const feature = event.features[0];
    const clusterId = feature?.properties?.cluster_id;

    if (!clusterId) {
      return;
    }

    const mapboxSource = mapRef.current.getSource('crashes');

    mapboxSource.getClusterExpansionZoom(clusterId, (err, zoom) => {
      if (err) {
        return;
      }

      mapRef.current.easeTo({
        center: feature.geometry.coordinates,
        zoom,
        duration: 500,
      });
    });
  };

  return (
    <MainLayout
      backgroundColor="gray.50"
      style={{
        padding: 0,
      }}
    >
      <Card style={{
        position: 'absolute',
        top: 20,
        right: 20,
        zIndex: 1,
        padding: '1rem',
      }}
      >
        <VStack align="start">
          <Select
            value={useDarkMode ? 'dark' : 'light'}
            onChange={(e) => setUseDarkMode(e.target.value === 'dark')}
          >
            <option value="dark">Dark</option>
            <option value="light">Light</option>
          </Select>
          <Checkbox
            isChecked={showCrashes}
            onChange={(e) => setShowCrashes(e.target.checked)}
          >
            Crashes
          </Checkbox>
          <Checkbox
            isChecked={showOnrc}
            onChange={(e) => setShowOnrc(e.target.checked)}
          >
            ONRC Categories
          </Checkbox>
        </VStack>
      </Card>
      <Map
        mapboxAccessToken="pk.eyJ1IjoibXN0ZWVua2FtcC1jeCIsImEiOiJjbGY2MmtqeDMwOHkyM3dxbXJwczI3dGsyIn0.Sgr5RRIMcqc_vsXlhElDrA"
        reuseMaps
        initialViewState={{
          longitude: 173.639847,
          latitude: -40.825650,
          pitch: 60, // pitch in degrees
          zoom: 5,
        }}
        mapStyle={`mapbox://styles/mapbox/${useDarkMode ? 'dark-v11' : 'streets-v12'}`}
        interactiveLayerIds={[
          clusterLayer.id,
          unclusteredPointLayer.id,
          onrcLayer.id,
        ]}
        onClick={onClick}
        onMouseMove={onHover}
        ref={mapRef}
      >
        {/* <Source
          id="roads"
          type="geojson"
          data="/centerline_data.geojson"
        >
          <Layer {...roadLayer} />
        </Source> */}

        {showOnrc && (
        <Source
          id="onrc"
          type="geojson"
          data="/national_centerline_with_onrc_2021_22.geojson"
        >
          <Layer {...onrcLayer} />
        </Source>
        )}

        {showCrashes && (
        <Source
          id="crashes"
          type="geojson"
          data="/crash_data.geojson"
          cluster
          clusterMaxZoom={10}
          clusterRadius={100}
        >
          <Layer {...clusterLayer} />
          <Layer {...clusterCountLayer} />
          <Layer {...unclusteredPointLayer} />
        </Source>
        )}

        {(hoveredOnrc || hoveredCrash) && (
        <div
          style={{
            position: 'absolute',
            fontFamily: 'Arial,sans-serif',
            padding: '0.5rem',
            background: 'rgba(0, 0, 0, 0.9)',
            color: '#fff',
            maxWidth: '300px',
            fontSize: '12px',
            zIndex: 9,
            pointerEvents: 'none',
            left: hoveredOnrc?.x || hoveredCrash?.x,
            top: hoveredOnrc?.y || hoveredCrash?.y,
          }}
        >
          {hoveredOnrc && (
          <div>
            {hoveredOnrc.feature.properties.name_ascii}
            {' '}
            :
            {' '}
            {hoveredOnrc.feature.properties.onrc_category}
          </div>
          )}
          {hoveredCrash && (
            <div>
              <div>
                Crash Date:
                {' '}
                {new Date(hoveredCrash.feature.properties.time).toLocaleDateString()}
              </div>
              <div>
                Deaths:
                {' '}
                {hoveredCrash.feature.properties.death_count}
              </div>
              <div>
                Serious Injuries:
                {' '}
                {hoveredCrash.feature.properties.injury_count}
              </div>
            </div>
          )}
        </div>
        )}
      </Map>
    </MainLayout>
  );
}
