import Card from '@src/modules/performance/components/Card';
import {
  Divider, Text, LinkBox, LinkOverlay, Center, Flex, Box, Icon,
} from '@chakra-ui/react';
import {
  FiArrowRight, FiChevronDown, FiChevronUp, FiMinus,
} from 'react-icons/fi';
import PlaceHolder from '@src/modules/performance/components/Placeholder';
import { Link as RouterLink } from 'react-router-dom';
import LockYearBadge from '@src/modules/performance/components/LockYearBadge';
import { TrafficLightColours as COLOURS } from '../charts/ChartColours';

function Indicator({ marker, colour, ...props }) {
  if (marker === 'up') {
    return (<FiChevronUp fontSize={48} color={colour} {...props} />);
  }
  if (marker === 'down') {
    return (<FiChevronDown fontSize={48} color={colour} {...props} />);
  }
  if (marker === 'flat') {
    return (<FiMinus fontSize={32} color={colour} {...props} />);
  }
  return null;
}

function TextWidget({
  title, value, colour, subtext1, subtext2, link, loading, marker, fontSize, lockYear, ...props
}) {
  return (
    <Card
      width="100%"
      p={0}
      borderWidth="1px"
      borderColor="white"
      cursor="pointer"
      _hover={{ borderColor: 'brand.orange.400' }}
      {...props}
    >
      <LinkBox
        p={2}
        height="100%"
        display="flex"
        justify="space-between"
        flexDirection="column"
      >
        <Box height={50}>
          <Center>
            <PlaceHolder isLoaded={!loading} height={5} width={190}>
              <Text textStyle="cardHeader">
                {title}
              </Text>
            </PlaceHolder>
          </Center>
        </Box>
        <Flex align="center" height={110}>
          <PlaceHolder isLoaded={!loading} width={190}>
            <Flex align="center" justify="center">
              <Text
                textStyle="cardText"
                color={COLOURS[colour] || '#616161'}
                fontSize={fontSize || 48}
                textAlign="center"
                dangerouslySetInnerHTML={{ __html: value }}
              />
              <Indicator
                marker={marker}
                colour={COLOURS[colour]}
              />
            </Flex>
          </PlaceHolder>
        </Flex>
        <Box pt={5}>
          <Center>
            <PlaceHolder isLoaded={!loading} height={5} width={190}>
              <Text textStyle="cardSubtext">
                {subtext1}
              </Text>
            </PlaceHolder>
          </Center>
          <Center>
            <PlaceHolder isLoaded={!loading} height={5} width={190}>
              <Text textStyle="cardSubtext">
                {subtext2}
              </Text>
            </PlaceHolder>
          </Center>
        </Box>
        <Divider pt={5} />
        <Flex justify="space-between" align="center" pt={2}>
          <LockYearBadge lockYear={lockYear} />
          <LinkOverlay as={RouterLink} to={link} display="inline-flex"><Icon as={FiArrowRight} boxSize={6} /></LinkOverlay>
        </Flex>
      </LinkBox>
    </Card>
  );
}

export default TextWidget;
