import { useEffect, useState } from 'react';
import {
  Text, Divider, LinkBox, LinkOverlay, Flex, Box, Center, Icon,
} from '@chakra-ui/react';
import Card from '@src/modules/performance/components/Card';
import PlaceHolder from '@src/modules/performance/components/Placeholder';
import PlaceholderCircle from '@src/modules/performance/components/PlaceholderCircle';
import { Link as RouterLink } from 'react-router-dom';
import { useInvestmentAuditKPI, useTechnicalAuditKPI } from '@src/modules/performance/context/reports-api-hooks';
import { FiArrowRight } from 'react-icons/fi';
import LockYearBadge from '@src/modules/performance/components/LockYearBadge';
import TrafficLight from '../indicators/TrafficLight';

const mapValueToText = (value) => {
  const text = [
    'Not Available', // blank/grey 0.0
    'Unacceptable', // red        0.25
    'Significant improvement needed', // orange     0.5
    'Some improvement needed', // yellow     0.75
    'Effective', // green      1.00
  ];
  return text[value * 4];
};

const mapColour = (value) => {
  const colours = ['GREY', 'RED', 'ORANGE', 'YELLOW', 'GREEN'];
  return colours[value * 4];
};

/* shows either on indicator or both */
function CoinvestorWidget({ ...rest }) {
  const { data: investmentAuditKPI, isLoading: investmentAuditKpiLoading } = useInvestmentAuditKPI();
  const { data: technicalAuditKPI, isLoading: technicalAuditKpiLoading } = useTechnicalAuditKPI();
  const [colourLeft, setColourLeft] = useState('GREY');
  const [colourRight, setColourRight] = useState('GREY');
  const [textLeft, setTextLeft] = useState('');
  const [textRight, setTextRight] = useState('');

  const [isTechnical, setIsTechnical] = useState(true);

  const link = '/performance/dashboard/coinvestor-assurance';

  useEffect(() => {
    if (investmentAuditKPI?.score) {
      setColourLeft(mapColour(investmentAuditKPI.score));
      setTextLeft(mapValueToText(investmentAuditKPI.score));
      setIsTechnical(investmentAuditKPI.isTechnical);
    }
    if (technicalAuditKPI?.score) {
      setColourRight(mapColour(technicalAuditKPI.score));
      setTextRight(mapValueToText(technicalAuditKPI.score));
    }
  }, [technicalAuditKPI, investmentAuditKPI]);

  const loading = investmentAuditKpiLoading && technicalAuditKpiLoading;

  return (
    <Card
      width="100%"
      p={0}
      borderWidth="1px"
      borderColor="white"
      cursor="pointer"
      _hover={{ borderColor: 'brand.orange.400' }}
      {...rest}
    >
      <LinkBox
        p={2}
        height="100%"
        display="flex"
        justify="space-between"
        flexDirection="column"
      >
        <Box>
          <Center>
            <PlaceHolder isLoaded={!loading} height={5} width={190}>
              <Text textStyle="cardHeader">
                Co-Investor Assurance
              </Text>
            </PlaceHolder>
          </Center>
          {isTechnical ? (
            <Flex direction="row" justifyContent="center" pt={5}>
              <PlaceHolder isLoaded={!loading} width={95}>
                <Text textStyle="cardSubtext">
                  Investment
                </Text>
                <LockYearBadge lockYear={investmentAuditKPI?.date} />
              </PlaceHolder>
              <PlaceHolder isLoaded={!loading} width={95}>
                <Text textStyle="cardSubtext">
                  Technical
                </Text>
                <LockYearBadge lockYear={technicalAuditKPI?.date} />
              </PlaceHolder>
            </Flex>
          ) : (
            <Flex direction="row" justifyContent="center" pt={5}>
              <PlaceHolder isLoaded={!loading} width={95}>
                <Text textStyle="cardSubtext">
                  Overall Rating
                </Text>
              </PlaceHolder>
            </Flex>
          )}
        </Box>
        <Box align="center">
          {isTechnical ? (
            <Flex direction="row" justifyContent="center">
              <PlaceholderCircle size="90px" isLoaded={!loading}>
                <TrafficLight colour={colourLeft} size="90px" mr={5} />
              </PlaceholderCircle>
              <PlaceholderCircle size="90px" isLoaded={!loading}>
                <TrafficLight colour={colourRight} size="90px" />
              </PlaceholderCircle>
            </Flex>
          ) : (
            <PlaceholderCircle size="110" isLoaded={!loading}>
              <TrafficLight colour={colourRight} size="110px" />
            </PlaceholderCircle>
          )}
        </Box>
        <Box height={50}>
          {isTechnical ? (
            <Flex direction="row">
              <PlaceHolder isLoaded={!loading} height={5} width={95}>
                <Text textStyle="cardSubtext">
                  {textLeft}
                </Text>
              </PlaceHolder>
              <PlaceHolder isLoaded={!loading} height={5} width={95}>
                <Text textStyle="cardSubtext">
                  {textRight}
                </Text>
              </PlaceHolder>
            </Flex>
          ) : (
            <Center>
              <PlaceHolder isLoaded={!loading} height={20} width={190}>
                <Text textStyle="cardSubtext" pt={5}>
                  {textRight}
                </Text>
              </PlaceHolder>
            </Center>
          )}
        </Box>
        <Flex flexGrow="1" pt={2} direction="column" justify="flex-end">
          <Divider />
          <Flex justify={!isTechnical ? 'space-between' : 'flex-end'} align="center" pt={2}>
            {!isTechnical && <LockYearBadge lockYear={technicalAuditKPI?.date} />}
            <LinkOverlay as={RouterLink} to={link} display="inline-flex"><Icon as={FiArrowRight} boxSize={6} /></LinkOverlay>
          </Flex>
        </Flex>
      </LinkBox>
    </Card>
  );
}

export default CoinvestorWidget;
