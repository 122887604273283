import {
  Box, Grid,
} from '@chakra-ui/react';
import MainHeader from '@src/components/layouts/header/MainHeader';
import { SideNavigation } from '@transport-insights/uikit';
import { currentDatabaseSelector, selectedLockYearState } from '@src/state/navContext';
import { useRecoilValue } from 'recoil';

export default function MainLayout({ showSidebar = true, children, ...other }) {
  const db = useRecoilValue(currentDatabaseSelector);
  const lockYear = useRecoilValue(selectedLockYearState);
  const dbString = db?.id ? db.id.toString() : null;

  return (
    <>
      <MainHeader />
      <Grid
        templateColumns={{ base: 'minmax(0, auto) minmax(0, 1fr)', xl: 'minmax(0, 18rem) minmax(0, 1fr)' }}
        height="calc(100vh - 80px)"
        mt="80px"
      >
        {showSidebar && (
        <SideNavigation
          db={dbString}
          lockYear={lockYear}
          reactRouterApps={['reg-insights', 'tce']}
        />
        )}
        <Box
          as="main"
          role="main"
          py={4}
          {...other}
        >
          { children }
        </Box>
      </Grid>
    </>
  );
}
