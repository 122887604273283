import { toNumber } from 'lodash';
import { atom, selector } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const NATIONAL_DATABASE_ID = 1000;

const { persistAtom } = recoilPersist();

const availableDatabasesState = atom({
  key: 'availableDatabases',
  default: [],
});

const selectedDatabaseIdState = atom({
  key: 'selectedDatabase',
  default: NATIONAL_DATABASE_ID,
  effects_UNSTABLE: [persistAtom],
});

const councilSelectorEnabledState = atom({
  key: 'nav/councilSelectorEnabled',
  default: true,
});

const currentDatabaseSelector = selector({
  key: 'rca/currentDatabase',
  get: ({ get }) => {
    const dbId = toNumber(get(selectedDatabaseIdState));
    return get(availableDatabasesState).find((d) => d.id === dbId);
  },
});

const selectedLockYearState = atom({
  key: 'lockYear',
  default: null,
  effects_UNSTABLE: [persistAtom],
});

const lockYears = selector({
  key: 'lockYears/get',
  get: ({ get }) => get(currentDatabaseSelector)?.lockYears || [],
});

const lockYearsSelectorEnabledState = atom({
  key: 'nav/lockYearSelectorEnabled',
  default: true,
});

export {
  availableDatabasesState,
  councilSelectorEnabledState,
  currentDatabaseSelector,
  lockYears,
  selectedDatabaseIdState,
  selectedLockYearState,
  lockYearsSelectorEnabledState,
};
