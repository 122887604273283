import { useEffect, useState } from 'react';
import {
  Flex,
  Text,
  RadioGroup,
  Radio,
  CheckboxGroup,
  Checkbox,
} from '@chakra-ui/react';
import { useRecoilValue } from 'recoil';
import { selectedLockYearState } from '@src/state/navContext';

export default function ReportToggles({
  options, text, isMulti, onSelectToggle,
}) {
  const [selectedToggleOption, setSelectedToggleOption] = useState(isMulti ? [...options.map((x) => x.value)] : options[0].value);
  const lockYear = useRecoilValue(selectedLockYearState);

  useEffect(() => {
    if (options?.length > 0) {
      if (isMulti) {
        setSelectedToggleOption([...options.map((x) => x.value)]);
      } else {
        setSelectedToggleOption(options[0].value);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleToggleOption = (val) => {
    setSelectedToggleOption(val);
    onSelectToggle(val);
  };

  const renderToggleLabel = (label) => {
    if (label.includes('Calendar Year')) {
      return `${label} (${lockYear.split('/')[0]})`;
    }
    if (label.includes('Financial Year')) {
      return `${label} (${lockYear})`;
    }
    return label;
  };

  return (
    <Flex
      align="center"
      borderBottom="1px solid"
      borderColor="gray.100"
      mb={2}
      pb={2}
    >
      <Text fontWeight="bold" fontSize="lg" mr={4}>{text ?? 'Filter by Type:'}</Text>
      {!isMulti
        && (
        <RadioGroup name="toggle-options" value={selectedToggleOption} onChange={(e) => handleToggleOption(e)}>
          {options.map(({ label, value }) => (
            <Radio
              key={value}
              value={value}
              size="sm"
              mr={4}
            >
              {renderToggleLabel(label)}
            </Radio>
          ))}
        </RadioGroup>
        )}
      {isMulti
        && (
        <CheckboxGroup name="toggle-options" value={selectedToggleOption} onChange={(e) => handleToggleOption(e)}>
          {options.map(({ label, value }) => (
            <Checkbox
              key={value}
              value={value}
              size="sm"
              mr={4}
            >
              {label}
            </Checkbox>
          ))}
        </CheckboxGroup>
        )}
    </Flex>
  );
}
