import { useEffect, useState } from 'react';
import { Text } from '@chakra-ui/react';

function HeaderDelivery({ queryInfo, percentage, fontSize }) {
  const [text, setText] = useState('No data');

  useEffect(() => {
    if (queryInfo?.data) {
      const { data: result } = queryInfo;
      if (result.nodata) {
        setText('No data');
      } else {
        setText(`${result[percentage]}%`);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryInfo]);

  if (!queryInfo?.data) {
    return null;
  }

  return (
    <Text mr={3} fontSize={fontSize}>{text}</Text>
  );
}

export default HeaderDelivery;
