import {
  Text, Box, Center, Icon, useTheme,
} from '@chakra-ui/react';
import { useRecoilValue } from 'recoil';
import { selectedDatabaseIdState } from '@src/state/navContext';
import Card from '@src/modules/performance/components/Card';
import PlaceHolder from '@src/modules/performance/components/Placeholder';
import PlaceholderCircle from '@src/modules/performance/components/PlaceholderCircle';

import LockYearBadge from '@src/modules/performance/components/LockYearBadge';
import { COLOURS } from '../shared/MapColours';

function CircleIcon(props) {
  return (
    <Icon viewBox="0 0 200 200" {...props}>
      <path
        fill="currentColor"
        d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0"
      />
    </Icon>
  );
}

function TrafficLightWidget({
  title, colour, subtext1, subtext2, loading, handleClick, selected, lockYear, ...other
}) {
  const theme = useTheme();
  const size = 90;
  const rcaId = useRecoilValue(selectedDatabaseIdState);

  return (
    <Card
      width="100%"
      padding={2}
      onClick={handleClick}
      borderWidth="1px"
      borderColor={selected ? 'brand.orange.400' : 'white'}
      cursor="pointer"
      _hover={{ borderColor: selected ? 'brand.orange.400' : 'brand.orange.300' }}
      _before={{
        content: '""',
        width: 0,
        height: 0,
        borderTop: '9px solid transparent',
        borderBottom: '9px solid transparent',
        borderLeft: '9px solid white',
        borderLeftColor: selected ? 'white' : 'transparent',
        right: '-9px',
        top: '50%',
        transform: 'translateY(-50%)',
        position: 'absolute',
        bgColor: 'transparent',
        zIndex: 2,
      }}
      _after={{
        content: '""',
        width: 0,
        height: 0,
        borderTop: '10px solid transparent',
        borderBottom: '10px solid transparent',
        borderLeft: '10px solid',
        borderLeftColor: selected ? theme.colors.brand.orange[500] : 'transparent',
        right: '-10px',
        top: '50%',
        transform: 'translateY(-50%)',
        position: 'absolute',
        bgColor: 'transparent',
        zIndex: 1,
      }}
      {...other}
    >
      <Box>
        <Center>
          <PlaceHolder isLoaded={!loading}>
            <Text textStyle="cardHeader">
              {title}
            </Text>
            <LockYearBadge lockYear={lockYear} />
          </PlaceHolder>
        </Center>
      </Box>
      {selected && rcaId < 1000 && subtext1 !== 'No data'
      && (
      <>
        <Box align="center">
          <PlaceholderCircle size="90" isLoaded={!loading}>
            <CircleIcon boxSize={size} color={COLOURS[colour]} />
          </PlaceholderCircle>
        </Box>
        <Box>
          <Center>
            <PlaceHolder isLoaded={!loading}>
              <Text textStyle="cardSubtext" fontWeight="bold">
                {subtext1}
              </Text>
            </PlaceHolder>
          </Center>
          <Center>
            <PlaceHolder isLoaded={!loading}>
              <Text textStyle="cardSubtext">
                {subtext2}
              </Text>
            </PlaceHolder>
          </Center>
        </Box>
      </>
      )}
    </Card>
  );
}

export default TrafficLightWidget;
