import {
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react';
import { currentDatabaseSelector } from '@src/state/navContext';
import { isNil } from 'lodash';
import { useRecoilValue } from 'recoil';

function TrafficGroupTable({
  trafficGroups,
  isLoading,
}) {
  // ensure we show the correct calculated rate for the selected RCA
  const rca = useRecoilValue(currentDatabaseSelector);
  // TCE-262 - show rate with two decimal places
  const cellFormatter = (value, decimalPoints = 3) => (isNil(value) ? '' : value.toFixed(decimalPoints));

  const renderCalculatedRates = (lookup) => {
    if (lookup && lookup[rca.id]) {
      if (lookup[rca.id].statusMessage !== '') {
        return (<Td colSpan={2}>{lookup[rca.id].statusMessage}</Td>);
      }

      // render the calculated rate for this RCA and the date it was calculated
      return (
        <>
          <Td isNumeric>{cellFormatter(lookup[rca.id].rate)}</Td>
          <Td>{new Date(lookup[rca.id].date).toDateString()}</Td>
        </>
      );
    }
    // otherwise just a blank space
    return (<Td colSpan={2} />);
  };

  const renderTrafficMix = (g) => {
    const hasCalculatedMix = g.calculatedTrafficMixes && g.calculatedTrafficMixes[rca.id];
    const trafficMix = hasCalculatedMix ? g.calculatedTrafficMixes[rca.id] : {
      pcCar: g.pcCar,
      pcLcv: g.pcLcv,
      pcMcv: g.pcMcv,
      pcHcvI: g.pcHcvI,
      pcHcvII: g.pcHcvII,
      pcBus: g.pcBus,
    };

    return (
      <>
        <Td isNumeric className={hasCalculatedMix ? '' : 'gray'}>{cellFormatter(trafficMix.pcCar)}</Td>
        <Td isNumeric className={hasCalculatedMix ? '' : 'gray'}>{cellFormatter(trafficMix.pcLcv)}</Td>
        <Td isNumeric className={hasCalculatedMix ? '' : 'gray'}>{cellFormatter(trafficMix.pcMcv)}</Td>
        <Td isNumeric className={hasCalculatedMix ? '' : 'gray'}>{cellFormatter(trafficMix.pcHcvI)}</Td>
        <Td isNumeric className={hasCalculatedMix ? '' : 'gray'}>{cellFormatter(trafficMix.pcHcvII)}</Td>
        <Td isNumeric className={hasCalculatedMix ? '' : 'gray'}>{cellFormatter(trafficMix.pcBus)}</Td>
      </>
    );
  };

  // This table renders the 'default traffic mix' as well.
  // This is not required, and should be removed
  // but we suspect that users will want to see it
  // so suggest just commenting it out.
  return (
    <VStack border="1px solid" borderColor="gray.100" borderRadius="md">
      <TableContainer whiteSpace="normal">
        <Table size="md">
          <Thead>
            <Tr>
              <Th colSpan={5} />
              <Th colSpan={7}>Traffic Mix</Th>
            </Tr>
            <Tr>
              <Th>Code</Th>
              <Th>Description</Th>
              <Th isNumeric>Manual Rate of Growth (%)</Th>
              <Th isNumeric>Calculated Rate of Growth (%)</Th>
              <Th>Date Calculated</Th>
              <Th isNumeric>%Car</Th>
              <Th isNumeric>%LCV</Th>
              <Th isNumeric>%MCV</Th>
              <Th isNumeric>%HCVI</Th>
              <Th isNumeric>%HCVII</Th>
              <Th isNumeric>%Buses</Th>
            </Tr>
          </Thead>

          {/* spinner */}
          {isLoading && (
          <Tbody>
            <Tr>
              <Td colSpan={12}>
                <Spinner />
              </Td>
            </Tr>
          </Tbody>
          )}

          {/* no data */}
          {!isLoading && trafficGroups?.length <= 0
        && (
        <Tbody>
          <Tr>
            <Td colSpan={12}>
              There are no traffic groups available for this RCA.
            </Td>
          </Tr>
        </Tbody>
        )}

          {/* actual data */}
          {!isLoading && trafficGroups?.length > 0
        && (
        <Tbody>
          {trafficGroups?.map((g) => (
            <Tr key={g.id}>
              <Td>{g.code}</Td>
              <Td>{g.description}</Td>
              <Td isNumeric className={g.manualRate > 0 ? '' : 'gray'}>{cellFormatter(g.manualRate, 2)}</Td>
              {renderCalculatedRates(g.calculatedRates)}
              {renderTrafficMix(g)}
            </Tr>
          ))}
        </Tbody>
        )}
        </Table>
      </TableContainer>
    </VStack>
  );
}

export default TrafficGroupTable;
