import { useDeliveryKPI } from '@src/modules/performance/context/reports-api-hooks';
import Card from '@src/modules/performance/components/Card';
import {
  Divider, Text, LinkBox, LinkOverlay, Center, Flex, Box, Icon,
} from '@chakra-ui/react';
import PlaceHolder from '@src/modules/performance/components/Placeholder';
import { Link as RouterLink } from 'react-router-dom';
import LockYearBadge from '@src/modules/performance/components/LockYearBadge';
import { FiArrowRight } from 'react-icons/fi';
import { TrafficLightColours as COLOURS } from '../charts/ChartColours';

function DeliveryWidget({ ...props }) {
  const { data, isLoading } = useDeliveryKPI();
  const textA = `${data?.currentPercentage || 'No data'}%`;
  const textB = `${data?.originalPercentage || 'No data'}%`;
  const textColour = data?.nodata ? 'GREY' : 'BLACK';

  return (
    <Card
      width="100%"
      p={0}
      borderWidth="1px"
      borderColor="white"
      cursor="pointer"
      _hover={{ borderColor: 'brand.orange.400' }}
      {...props}
    >
      <LinkBox
        p={2}
        height="100%"
        display="flex"
        justify="space-between"
        flexDirection="column"
      >
        <Box>
          <Center>
            <PlaceHolder isLoaded={!isLoading} height={5} width={190}>
              <Text textStyle="cardHeader">
                Delivery
              </Text>
            </PlaceHolder>
          </Center>
        </Box>
        <Flex direction="column" mt={2}>
          <Flex align="center">
            <PlaceHolder isLoaded={!isLoading} width={190}>
              <Flex align="center" justify="center">
                <Text
                  textStyle="cardText"
                  color={COLOURS[textColour] || '#616161'}
                  fontSize={data?.currentPercentage ? 48 : 28}
                  textAlign="center"
                  lineHeight={1}
                >
                  {textA}
                </Text>
              </Flex>
            </PlaceHolder>
          </Flex>
          <Box>
            <Center>
              <PlaceHolder isLoaded={!isLoading} height={5} width={190}>
                <Text textStyle="cardSubtext">
                  Current
                  {' '}
                  {data?.budgetPeriod}
                  {' '}
                  LTP
                  <br />
                  Approved Funding Spent
                </Text>
              </PlaceHolder>
            </Center>
          </Box>
          <Flex align="center" mt={6}>
            <PlaceHolder isLoaded={!isLoading} width={190}>
              <Flex align="center" justify="center">
                <Text
                  textStyle="cardText"
                  color={COLOURS[textColour] || '#616161'}
                  fontSize={28}
                  textAlign="center"
                >
                  {textB}
                </Text>
              </Flex>
            </PlaceHolder>
          </Flex>
          <Box>
            <Center>
              <PlaceHolder isLoaded={!isLoading} height={5} width={190}>
                <Text textStyle="cardSubtext">
                  Original
                  {' '}
                  {data?.budgetPeriod}
                  {' '}
                  LTP
                  <br />
                  Approved Funding Spent
                </Text>
              </PlaceHolder>
            </Center>
          </Box>
        </Flex>

        <Flex flexGrow="1" pt={2} direction="column" justify="flex-end">
          <Divider />
          <Flex justify="space-between" align="center" pt={2}>
            <LockYearBadge lockYear={data?.lockYear} />
            <LinkOverlay as={RouterLink} to="/performance/dashboard/delivery" display="inline-flex"><Icon as={FiArrowRight} boxSize={6} /></LinkOverlay>
          </Flex>
        </Flex>
      </LinkBox>
    </Card>
  );
}

export default DeliveryWidget;
