import { useReducer, useState, useEffect } from 'react';
import {
  Box,
  Text,
  HStack,
  Select,
  Flex,
  Button,
  Spinner,
} from '@chakra-ui/react';
import { uniqBy } from 'lodash';

const initialState = {
  corridor: '',
  regionalCouncil: '',
  districtCouncil: '',
  noc: '',
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'setCorridor':
      return {
        ...state,
        corridor: action.corridor,
      };
    case 'setRegionalCouncil':
      return {
        ...state,
        regionalCouncil: action.regionalCouncil,
      };
    case 'setDistrictCouncil':
      return {
        ...state,
        districtCouncil: action.districtCouncil,
      };
    case 'setNoc':
      return {
        ...state,
        noc: action.noc,
      };
    case 'setActiveHnoFilter':
      return {
        ...action.activeHnoFilter,
      };
    case 'reset':
      return {
        ...initialState,
      };
    default:
      throw new Error();
  }
};

export default function HnoFilter({
  selectedHno, data = [], isLoading, activeHnoFilter = null, buttonLabel,
}) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [filteredCorridorData, setFilteredCorridorData] = useState([]);
  const [filteredRegionalData, setFilteredRegionalData] = useState([]);
  const [filteredDistrictData, setFilteredDistrictData] = useState([]);
  const [filteredNocData, setFilteredNocData] = useState([]);

  const corridors = uniqBy(filteredCorridorData, 'corridorName')
    .map((x) => ({ id: x.corridor, name: x.corridorName }))
    .sort((a, b) => a.name.localeCompare(b.name));
  const regions = uniqBy(filteredRegionalData, 'regionName')
    .map((x) => ({ id: x.road_region, name: x.regionName }))
    .sort((a, b) => a.name.localeCompare(b.name));
  const districts = uniqBy(filteredDistrictData, 'tlaName')
    .map((x) => ({ id: x.road_council, name: x.tlaName }))
    .sort((a, b) => a.name.localeCompare(b.name));
  const nocs = uniqBy(filteredNocData, 'noc')
    .map((x) => ({ id: x.noc, name: x.noc }))
    .sort((a, b) => a.name.localeCompare(b.name));

  useEffect(() => {
    setFilteredCorridorData(data.filter(
      (x) => ((state.regionalCouncil === '' || x.road_region === state.regionalCouncil)
      && (state.districtCouncil === '' || x.road_council === state.districtCouncil)
      && (state.noc === '' || x.noc === state.noc)),
    ));
    setFilteredRegionalData(data.filter(
      (x) => ((state.corridor === '' || x.corridor === state.corridor)
      && (state.districtCouncil === '' || x.road_council === state.districtCouncil)
      && (state.noc === '' || x.noc === state.noc)),
    ));
    setFilteredDistrictData(data.filter(
      (x) => ((state.corridor === '' || x.corridor === state.corridor)
      && (state.regionalCouncil === '' || x.road_region === state.regionalCouncil)
      && (state.noc === '' || x.noc === state.noc)),
    ));
    setFilteredNocData(data.filter(
      (x) => ((state.corridor === '' || x.corridor === state.corridor)
      && (state.regionalCouncil === '' || x.road_region === state.regionalCouncil)
      && (state.districtCouncil === '' || x.road_council === state.districtCouncil)),
    ));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    if (activeHnoFilter !== null) {
      dispatch({ type: 'setActiveHnoFilter', activeHnoFilter });
    }
  }, [activeHnoFilter]);

  const getHnoNames = () => {
    let filterName = '';
    const regionName = data.find((x) => x.road_region === state.regionalCouncil)?.regionName || null;
    const tlaName = data.find((x) => x.road_council === state.districtCouncil)?.tlaName || null;
    const noc = data.find((x) => x.noc === state.noc)?.noc || null;
    const corridorName = data.find((x) => x.corridor === state.corridor)?.corridorName || null;

    if (regionName) {
      filterName += `Waka Kotahi: ${regionName}; `;
    }

    if (tlaName) {
      filterName += `${tlaName}; `;
    }

    if (noc) {
      filterName += `${noc}; `;
    }

    if (corridorName) {
      filterName += `${corridorName}; `;
    }

    if (filterName === '') {
      filterName = 'Waka Kotahi';
    } else {
      filterName = filterName.slice(0, -2);
    }

    return {
      filterName, regionName, tlaName, corridorName, noc,
    };
  };

  const handleApply = () => {
    const {
      filterName, regionName, tlaName, corridorName,
    } = getHnoNames();
    selectedHno({
      ...state, regionName, tlaName, corridorName, filterName,
    });
  };

  if (isLoading) return <Spinner />;

  return (
    <HStack spacing={4}>
      <Box>
        <Text mb={2} fontWeight="bold" fontSize="sm">Corridor</Text>
        <Select onChange={(e) => dispatch({ type: 'setCorridor', corridor: e.target.value })} value={state.corridor}>
          <option value="">All</option>
          {corridors.map((corridor) => (<option key={`corridor_${corridor.id}`} value={corridor.id}>{corridor.name}</option>))}
        </Select>
      </Box>
      <Box>
        <Text mb={2} fontWeight="bold" fontSize="sm">Regional Council</Text>
        <Select onChange={(e) => dispatch({ type: 'setRegionalCouncil', regionalCouncil: e.target.value })} value={state.regionalCouncil}>
          <option value="">All</option>
          {regions.map((region) => (<option key={`region_${region.id}`} value={region.id}>{region.name}</option>))}
        </Select>
      </Box>
      <Box>
        <Text mb={2} fontWeight="bold" fontSize="sm">District Council</Text>
        <Select onChange={(e) => dispatch({ type: 'setDistrictCouncil', districtCouncil: e.target.value })} value={state.districtCouncil}>
          <option value="">All</option>
          {districts.map((district) => (<option key={`district_${district.id}`} value={district.id}>{district.name}</option>))}
        </Select>
      </Box>
      <Box>
        <Text mb={2} fontWeight="bold" fontSize="sm">NOC</Text>
        <Select onChange={(e) => dispatch({ type: 'setNoc', noc: e.target.value })} value={state.noc}>
          <option value="">All</option>
          {nocs.map((noc) => (<option key={`noc_${noc.id}`} value={noc.id}>{noc.name}</option>))}
        </Select>
      </Box>
      <Flex alignSelf="flex-end">
        <Button onClick={handleApply}>{buttonLabel}</Button>
        <Button ml={2} variant="outline" colorScheme="gray" onClick={() => dispatch({ type: 'reset' })}>Reset</Button>
      </Flex>
    </HStack>
  );
}
