import { Route, Switch, useRouteMatch } from 'react-router-dom';
import ActivityManagement from '@src/modules/performance/views/reports/activity-management/ActivityManagement';
import ServicePerformance from '@src/modules/performance/views/reports/ServicePerformance';
import CoInvestorAssurance from '@src/modules/performance/views/reports/CoInvestorAssurance';
import Delivery from '@src/modules/performance/views/reports/Delivery';
import DeliveryPerformance from '@src/modules/performance/views/reports/DeliveryPerformance';
import AmenityCondition from '@src/modules/performance/views/reports/AmenityCondition';
import Safety from '@src/modules/performance/views/reports/Safety';
import NetworkAvailability from '@src/modules/performance/views/reports/NetworkAvailability';
import TerritorialActivity from '@src/modules/performance/views/reports/TerritorialActivity';
import RoadNetworkUse from '@src/modules/performance/views/reports/RoadNetworkUse';
import PublicTransport from '@src/modules/performance/views/reports/PublicTransport';
import Map from '../views/map';
import Dashboard from '../views/reports/Dashboard';
import LevelsOfService from '../views/levels-of-service';
import AnnualReports from '../views/annualreports';
import NetworkPhysicalCharacteristics from '../views/reports/NetworkPhysicalCharacteristics';
import NetworkCharacteristics from '../views/reports/NetworkCharacteristics';
import ActivityManagementPlans from '../views/activity-management-plans';
import CrashMapPoc from '../views/crash-map-poc';
import PlanningQualityPillars from '../views/reports/activity-management/PlanningQualityPillars';
import MaintenanceOperations from '../views/reports/MaintenanceOperations';

export default function Routes() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/dashboard/activity-management/planning-quality-pillars`} component={PlanningQualityPillars} exact />
      <Route path={`${path}/annual-reports`} component={AnnualReports} />
      <Route path={`${path}/dashboard/delivery-performance`} component={DeliveryPerformance} />
      <Route path={`${path}/dashboard/amenity-and-condition`} component={AmenityCondition} />
      <Route path={`${path}/dashboard/activity-management`} component={ActivityManagement} />
      <Route path={`${path}/dashboard/coinvestor-assurance`} component={CoInvestorAssurance} />
      <Route path={`${path}/dashboard/delivery`} component={Delivery} />
      <Route path={`${path}/dashboard/network-availability`} component={NetworkAvailability} />
      <Route path={`${path}/dashboard/network-physical-characteristics`} component={NetworkPhysicalCharacteristics} />
      <Route path={`${path}/dashboard/public-transport`} component={PublicTransport} />
      <Route path={`${path}/dashboard/service-performance`} component={ServicePerformance} />
      <Route path={`${path}/dashboard/road-network-use`} component={RoadNetworkUse} />
      <Route path={`${path}/dashboard/territorial-activity`} component={TerritorialActivity} />
      <Route path={`${path}/dashboard/safety`} component={Safety} />
      <Route path={`${path}/dashboard/delivery`} component={Delivery} />
      <Route path={`${path}/dashboard`} component={Dashboard} />
      <Route path={`${path}/asset-condition`} component={LevelsOfService} />
      <Route path={`${path}/maintenance-operations`} component={MaintenanceOperations} exact />
      <Route path={`${path}/map`} component={Map} />
      <Route path={`${path}/networkchar`} component={NetworkCharacteristics} />
      <Route path={`${path}/network-characteristics`} component={NetworkCharacteristics} />
      <Route path={`${path}/reports/network-physical-characteristics`} component={NetworkPhysicalCharacteristics} />
      <Route path={`${path}/reports/activity-management`} component={ActivityManagement} />
      <Route path={`${path}/activity-management-plans`} component={ActivityManagementPlans} />
      <Route path={`${path}/crash-map`} component={CrashMapPoc} />
      <Route path={`${path}/`} component={Map} />
    </Switch>
  );
}
