import { useRecoilValue } from 'recoil';
import { currentDatabaseSelector, selectedLockYearState } from '@src/state/navContext';
import { useQuery } from 'react-query';
import useOnfApi from '../api/useOnfApi';

export function useNetworkSelector() {
  const rca = useRecoilValue(currentDatabaseSelector);
  const lockYear = useRecoilValue(selectedLockYearState);
  const api = useOnfApi();

  return useQuery(
    ['network-selector-data', rca?.id, lockYear],
    async () => api.getNetworkSelectorData(rca?.id, lockYear),
    {
      enabled: rca?.id !== null && rca !== undefined && lockYear !== null,
    },
  );
}
