import { Spinner, HStack, Select } from '@chakra-ui/react';
import { usePortalNavContext } from '@src/context/nav';
import {
  useRecoilValue, useRecoilState, useSetRecoilState, useResetRecoilState,
} from 'recoil';
import { useLocation } from 'react-router-dom';
import {
  availableDatabasesState, selectedDatabaseIdState, currentDatabaseSelector,
  lockYears, selectedLockYearState, councilSelectorEnabledState, lockYearsSelectorEnabledState,
} from '@src/state/navContext';
import { useEffect } from 'react';
import { useMutation } from 'react-query';
import { useUserInfo } from '@transport-insights/uikit';
import { useSettingsApi } from '@src/modules/settings/api/settings';

function RcaSelector({ commonSelectProps, filters }) {
  const api = useSettingsApi();
  const { isLoggedIn } = useUserInfo();
  const location = useLocation();
  const setSelectedDatabaseId = useSetRecoilState(selectedDatabaseIdState);
  const availableDatabases = useRecoilValue(availableDatabasesState);
  const currentDatabase = useRecoilValue(currentDatabaseSelector);
  const setSelectedLockYear = useResetRecoilState(selectedLockYearState);
  const councilSelectorEnabled = useRecoilValue(councilSelectorEnabledState);
  const filteredDatabases = filters.length === 0
    ? availableDatabases // Return everything if there are no filters
    : availableDatabases.map((x) => {
      const matchingFilters = filters.filter((f) => x.type === f.value);

      if (matchingFilters.length === 0) {
        return null;
      }

      const shouldShow = matchingFilters.some((f) => f.action === 'show');
      const shouldHide = matchingFilters.some((f) => f.action === 'hide');
      const shouldBeDisabled = matchingFilters.some((f) => f.action === 'disable');

      if (shouldShow) {
        return x;
      } if (shouldHide) {
        return null;
      } if (shouldBeDisabled) {
        return { ...x, isDisabled: true };
      }
      return null;
    }).filter(Boolean); // Remove null values from the filtered array

  const { mutate } = useMutation(async (db) => {
    if (isLoggedIn) {
      await api.setCurrentDatabase(db);
    }
    return db;
  });

  const onChangeDatabase = (e) => {
    const db = e.target.value;
    mutate(db);
    setSelectedDatabaseId(db);
    setSelectedLockYear(null);
  };

  // Select a default database if it doesn't exist in the filtered list
  if (!filteredDatabases.some((x) => x.id === currentDatabase?.id)) {
    if (location.pathname.includes('regional-reporting')) {
      setSelectedDatabaseId(currentDatabase?.regionId);
    } else {
      setSelectedDatabaseId(filteredDatabases[0].id);
    }
  }

  return (
    <Select
      value={currentDatabase?.id}
      onChange={onChangeDatabase}
      isDisabled={!councilSelectorEnabled}
      width="auto"
      {...commonSelectProps}
    >
      {
        filteredDatabases?.map((db, i) => (
          <option key={i} value={db.id} style={{ color: 'black' }} disabled={db.isDisabled}>
            {db.name}
          </option>
        ))
      }
    </Select>

  );
}

function LockYearsSelector({ hideYearDropdown, commonSelectProps }) {
  const currentDatabase = useRecoilValue(currentDatabaseSelector);
  const [selectedLockYear, setSelectedLockYear] = useRecoilState(selectedLockYearState);
  const availableLockYears = useRecoilValue(lockYears);
  const councilSelectorEnabled = useRecoilValue(councilSelectorEnabledState);

  const lockYearsSelectorEnabled = useRecoilValue(lockYearsSelectorEnabledState);

  // Sets the lock year to the first one in the list
  // This is appropriate for the first render and when a database change occured
  useEffect(() => {
    if (selectedLockYear === null) {
      // select the last year if possible
      if (currentDatabase?.lockYears?.length > 0) {
        setSelectedLockYear(currentDatabase.lockYears[currentDatabase.lockYears.length - 1] || null);
      }
    }
  }, [availableLockYears, currentDatabase?.lockYears, selectedLockYear, setSelectedLockYear]);
  return (
    <Select
      value={selectedLockYear ?? ''}
      onChange={(e) => setSelectedLockYear(e.target.value)}
      width="auto"
      isDisabled={!councilSelectorEnabled || !lockYearsSelectorEnabled}
      display={hideYearDropdown ? 'none' : 'block'}
      {...commonSelectProps}
    >
      {availableLockYears?.map((lockYear, i) => (
        <option key={i} value={lockYear ?? ''} style={{ color: 'black' }}>
          {lockYear}
        </option>
      ))}
    </Select>
  );
}

export default function NavContextSwitcher() {
  const { isLoading } = usePortalNavContext();
  const location = useLocation();
  const routesToHideLockYearOn = [
    '/home', '/performance/map', '/performance/dashboard', 'performance/crash-map',
    '/performance/annual-reports', '/performance/activity-management-plans',
    '/tce/traffic-group', 'tce/estimates', '/regional-reporting'];
  const hideYearDropdown = routesToHideLockYearOn.some((route) => location.pathname.includes(route)) || location.pathname === '/';
  const filters = [];

  // Only show regions if on regional reporting pages
  if (location.pathname.includes('regional-reporting')) {
    filters.push({ action: 'show', value: 'Region' });
  }

  // Disable all networks apart from RCAs on performance pages
  if (location.pathname.includes('performance')) {
    filters.push({ action: 'disable', value: 'Region' });
    filters.push({ action: 'disable', value: 'PeerGroup' });
    filters.push({ action: 'disable', value: 'National' });
    filters.push({ action: 'show', value: 'RCA' });
  }

  if (isLoading) return <Spinner />;

  const commonSelectProps = {
    color: 'brand.gray.700',
    backgroundColor: 'white',
    borderRadius: 'sm',
    border: 'none',
    fontWeight: 'bold',
    boxShadow: '1px 1px 3px rgba(0,0,0,0.15)',
    _hover: {
      backgroundColor: 'rgba(255,255,255,0.8)',
      cursor: 'pointer',
    },
  };

  return (
    <HStack>
      <RcaSelector commonSelectProps={commonSelectProps} filters={filters} />
      <LockYearsSelector hideYearDropdown={hideYearDropdown} commonSelectProps={commonSelectProps} />
    </HStack>
  );
}
