/* eslint-disable react/display-name */
import { forwardRef } from 'react';
import {
  Box, Accordion, AccordionIcon, AccordionItem, AccordionButton, AccordionPanel, Text,
} from '@chakra-ui/react';

const InfoContentCollapse = forwardRef(({
  title, html, index, setIndex,
}, ref) => {
  // ensure the accordion can still be collapsed by the user
  // the collapsed state is tracked in the parent component
  const doClick = () => {
    if (!setIndex) return;

    if (index !== null) {
      setIndex(null);
    } else {
      setIndex(0);
    }
  };

  return (
    <>
      <Text py={4} fontSize="xs">The information presented in these dashboards is based on the latest data that is currently available and is being updated regularly.</Text>
      <Accordion ref={ref} allowToggle mt={2} index={index}>
        <AccordionItem style={{ borderStyle: 'none' }}>
          <AccordionButton
            display="inline-flex"
            border="1px solid"
            borderColor="gray.100"
            borderRadius="md"
            width="auto"
            onClick={doClick}
          >
            <Text>{title}</Text>
            <AccordionIcon ml={15} size={18} style={{ stroke: 'gray', strokeWidth: '2px' }} />
          </AccordionButton>
          <AccordionPanel pl={1} pb={4} mt={5}>
            <Box dangerouslySetInnerHTML={{ __html: html }} />
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </>
  );
});

export default InfoContentCollapse;
