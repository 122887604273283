import { useRecoilValue } from 'recoil';
import { currentDatabaseSelector } from '@src/state/navContext';
import { useQuery } from 'react-query';
import useTceApi from '../api/useTceApi';

export function useRcaAccess() {
  const rca = useRecoilValue(currentDatabaseSelector);
  const api = useTceApi();

  const { isLoading, data, error } = useQuery(
    ['tce-rcaaccess', rca?.id],
    async () => api.hasAccess(rca?.id),
    {
      enabled: rca?.id !== null,
    },
  );

  return {
    isLoading,
    data: data?.hasAccess,
    error,
  };
}
