import {
  Box,
  Container,
  Flex, Text,
  Wrap,
} from '@chakra-ui/react';
import { useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import MainLayout from '@src/components/layouts/main';
import { useActivityManagementKPI } from '@src/modules/performance/context/map-api-hooks';
import {
  useCoinvestmentPlanningQuality,
  useDQAssetManagement,
  usePlanningQuality, useSmartBuyerSelfAssessment,
} from '@src/modules/performance/context/reports-api-hooks';
import Pagination from '../../../components/Pagination';
import ReportCard from '../../../components/ReportCard';
import { mapActivityToText } from '../../map/shared/IndicatorText';
import InfoContentCollapse from '../components/information/InfoContentCollapse';
import InfoScroller from '../components/information/InfoScroller';
import Legend from '../components/information/Legend';
import HeaderTrafficLight from '../components/selfloading/HeaderTrafficLight';
import SLLineChart from '../components/selfloading/SLLineChart';
import TitledTrafficLight from '../components/selfloading/TitledTrafficLight';
import html from '../help/ActivityManagement.html';

const subheader = 'Planning, Procurement and Data Quality';

function ActivityManagement() {
  const { pathname } = useLocation();
  const reportUrl = pathname.split('/').pop();
  const planningQuality = usePlanningQuality();
  const coinvestmentPlanningQuality = useCoinvestmentPlanningQuality();
  const { data: activityKpi } = useActivityManagementKPI();
  const smartBuyerSelfAssessment = useSmartBuyerSelfAssessment();
  const dQAssetManagement = useDQAssetManagement();
  // this controls the expansion of the help section
  const [index, setIndex] = useState(null);

  const helpSectionRef = useRef(null);
  // triggered when the user clicks on the help icon in the title
  const expandHelpSection = () => {
    setIndex(0);
  };

  return (
    <MainLayout backgroundColor="gray.50">
      <Container maxW="full">
        <Pagination currentReportUrl={reportUrl} />
        <ReportCard mt={2} pt={0}>
          <ReportCard.Header
            title="Activity Management"
            subtitle={subheader}
            info={<InfoScroller expandHelpSection={expandHelpSection} ref={helpSectionRef} />}
          >
            <Flex>
              <Box mr={3}>
                <HeaderTrafficLight size="65px" endpointData={activityKpi} fieldname="activity" mapText={mapActivityToText} />
              </Box>
              <Box pr={15}>
                <Text mt={3} fontWeight="bold" color="gray">
                  Composite
                  <br />
                  Indicator
                </Text>
              </Box>
            </Flex>
          </ReportCard.Header>
          <ReportCard.Body>
            <Wrap spacing={4}>
              <Box>
                <Text mb={5} textStyle="reportSectionHeader">Activity Management Planning</Text>
                <Flex flexDirection="column">
                  <Flex alignContent="start">
                    <Box>
                      <TitledTrafficLight
                        title="Planning quality"
                        text=" out of "
                        queryInfo={planningQuality}
                        precision={2}
                      />
                      <Text align="center" mt={2} fontSize="xs">
                        Source: Te Ringa Maimoa
                        <br />
                        AMP Assessment
                      </Text>
                    </Box>
                    <Box>
                      <TitledTrafficLight
                        title="Co-investment planning quality"
                        text=" out of "
                        queryInfo={coinvestmentPlanningQuality}
                        precision={2}
                      />
                      <Text align="center" mt={2} fontSize="xs">
                        Source: Waka Kotahi
                        <br />
                        AMP Assessment
                      </Text>
                    </Box>
                  </Flex>
                  <Legend
                    mt={5}
                    values={['Good', 'Fit for purpose', 'Room for improvement', 'Not assessed']}
                    colours={['GREEN', 'YELLOW', 'ORANGE', 'GREY']}
                    vertical
                  />
                </Flex>
              </Box>
              <Box>
                <Text mb={5} textStyle="reportSectionHeader">Procurement</Text>
                <Flex flexFlow="column">
                  <Flex alignContent="start">
                    <TitledTrafficLight
                      title="Smart buyer self-assessment"
                      text=" out of "
                      queryInfo={smartBuyerSelfAssessment}
                      lockYearOverride={2020}
                    />
                  </Flex>
                  {smartBuyerSelfAssessment.data?.source
                    && (
                      <Text align="center" mt={2} fontSize="xs">
                        {smartBuyerSelfAssessment.data?.source}
                        <br />
                        Self-Assessment
                      </Text>
                    )}
                  <Legend
                    mt={5}
                    values={['A smart buyer', 'Developing', 'Limited', 'Basic', 'Not assessed']}
                    colours={['GREEN', 'YELLOW', 'ORANGE', 'RED', 'GREY']}
                    vertical
                  />
                </Flex>
              </Box>
              <Box>
                <Text mb={5} textStyle="reportSectionHeader">Data Quality</Text>
                <Flex alignContent="start" className="gap">
                  <SLLineChart
                    queryInfo={dQAssetManagement}
                    title1="Asset management at expected"
                    title2="standard (%) and Score"
                    labels={['TA', 'AM Score', 'National', 'Peer Group']}
                    colours={['lightblue', 'red', 'grey', 'black']}
                    unitprefix=""
                    unitpostfix=""
                    width={400}
                  />
                </Flex>
                <Text align="center" mt={4} fontSize="xs">Source: Te Ringa Maimoa Transport Insights</Text>
              </Box>
            </Wrap>
          </ReportCard.Body>
        </ReportCard>
        <InfoContentCollapse
          ref={helpSectionRef}
          title="Information Sources"
          html={html}
          index={index}
          setIndex={setIndex}
        />
      </Container>
    </MainLayout>
  );
}

export default ActivityManagement;
