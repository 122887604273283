export const reportsList = [
  {
    url: 'activity-management',
    name: 'Activity Management',
    subReports: [
      {
        url: 'activity-management/planning-quality-pillars',
        name: 'Planning Quality Pillars',
        enabled: false,
      },
    ],
    enabled: true,
  },
  {
    url: 'service-performance',
    name: 'Service Performance',
    enabled: true,
  },
  {
    url: 'coinvestor-assurance',
    name: 'Coinvestor Assurance',
    enabled: true,
  },
  {
    url: 'delivery',
    name: 'Delivery',
    enabled: true,
    relevantLinks: [
      {
        url: '/performance/maintenance-operations',
        title: 'Maintenance Operations & Renewal',
      },
      {
        url: '/onf/transport-outcomes/ep3',
        title: 'ONF: Maintenance Costs',
      },
      {
        url: '/onf/transport-outcomes/ep4',
        title: 'ONF: Sealed Road Pavement Rehabilitation & Resurfacing',
      },
    ],
  },
  {
    url: 'delivery-performance',
    name: 'Delivery Performance',
    enabled: true,
    relevantLinks: [
      {
        url: '/onf/transport-outcomes/ia1',
        title: 'ONF: Smooth Travel Exposure',
      },
      {
        url: '/onf/transport-outcomes/ia2',
        title: 'ONF: Peak Roughness',
      },
      {
        url: '/onf/transport-outcomes/ep4',
        title: 'ONF: Sealed Road Pavement Rehabilitation & Resurfacing',
      },
    ],
  },
  {
    url: 'amenity-and-condition',
    name: 'Amenity and Condition',
    enabled: true,
    relevantLinks: [
      {
        url: '/onf/transport-outcomes/ia1',
        title: 'ONF: Smooth Travel Exposure',
      },
      {
        url: '/onf/transport-outcomes/ia2',
        title: 'ONF: Peak Roughness',
      },
      {
        url: '/onf/transport-outcomes/ep4',
        title: 'ONF: Sealed Road Pavement Rehabilitation & Resurfacing',
      },
    ],
  },
  {
    url: 'safety',
    name: 'Safety',
    relevantLinks: [
      {
        url: '/onf/transport-outcomes/hsp1',
        title: 'ONF: Safe Travel - Deaths and Serious Injuries',
      },
      {
        url: '/onf/transport-outcomes/hsp2',
        title: 'ONF: Safe Travel - Personal/Collective Risk',
      },
    ],
    enabled: true,
  },
  {
    url: 'public-transport',
    name: 'Public Transport and Total Mobility',
    enabled: true,
  },
  {
    url: 'territorial-activity',
    name: 'Territorial Activity',
    enabled: true,
  },
  {
    url: 'network-physical-characteristics',
    name: 'Network Physical Characteristics',
    enabled: true,
  },
  {
    url: 'road-network-use',
    name: 'Road Network Use',
    enabled: true,
  },
  {
    url: 'network-availability',
    name: 'Network Availability and Accessibility',
    relevantLinks: [
      {
        url: '/onf/transport-outcomes/ep1',
        title: 'ONF: Unplanned Road Closures',
      },
      {
        url: '/onf/transport-outcomes/ep2',
        title: 'ONF: Heavy Vehicle Accessibility',
      },
    ],
    enabled: true,
  },
];
