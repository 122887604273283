import { useEffect, useState } from 'react';
import { useServicePerformanceKPI } from '@src/modules/performance/context/reports-api-hooks';
import PieChartWidget from './PieChartWidget';
import { TrafficLightColours as COLOURS } from '../charts/ChartColours';

const colourMap = (value) => {
  if (value <= -1.0) return 'RED';
  if (value >= 1.0) return 'GREEN';
  if (value >= 0.5) return 'ORANGE';
  return 'GREY';
};

function ServicePerformanceWidget({ ...props }) {
  const { data: servicePerformanceKPI, isLoading } = useServicePerformanceKPI();
  const [colours, setColours] = useState(['GREY']);
  const [data, setData] = useState([1]);

  useEffect(() => {
    if (servicePerformanceKPI && !isLoading) {
      const v = [];
      const c = [];
      servicePerformanceKPI.sort((a, b) => b.index - a.index).forEach((r) => {
        const colour = COLOURS[colourMap(r.index)];
        c.push(colour);
        v.push(r.count > 0 ? r.count / 10 : r.count);
      });
      setColours(c);
      setData(v);
    }
  }, [servicePerformanceKPI, isLoading]);

  return (
    <PieChartWidget
      title="Service Performance"
      values={data}
      colours={colours}
      lockYear={servicePerformanceKPI?.[0]?.lockYear}
      subtext="2021-24 LTP Targets Achieved"
      link="/performance/dashboard/service-performance"
      loading={isLoading}
      {...props}
    />
  );
}

export default ServicePerformanceWidget;
