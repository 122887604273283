import {
  Box, Container, Drawer,
  DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader,
  DrawerOverlay, Flex, Heading, IconButton, Link, List,
  ListItem, Text, Tooltip, useDisclosure, VStack,
} from '@chakra-ui/react';
import MainLayout from '@src/components/layouts/main';
import EmptyState from '@src/components/shared/EmptyState';
import LoadingSpinner from '@src/components/shared/LoadingSpinner';
import { useAssets, useDataConfidence } from '@src/modules/performance/context/levels-of-service-api-hooks';
import { selectedDatabaseIdState, selectedLockYearState } from '@src/state/navContext';
import { groupBy, isEmpty } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import GaugeChart from 'react-gauge-chart';
import { FiMoreHorizontal } from 'react-icons/fi';
import { useRecoilValue } from 'recoil';
import AssetInspector from './AssetInspector';
import AssetSelector from './AssetSelector';
import ConditionSelector from './ConditionSelector';

function MoreDrawer({ children }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef();
  return (
    <>
      <Tooltip label="More Information">
        <IconButton
          ref={btnRef}
          onClick={onOpen}
          icon={<FiMoreHorizontal />}
          borderRadius="full"
          colorScheme="gray"
          ml={3}
        />
      </Tooltip>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
        size="lg"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader />
          <DrawerBody>
            {children}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}

const NATIONAL_DB_ID = 1000;
const WAKA_KOTAHI_DB_ID = 81;

function LevelsOfService() {
  const [summarize, setSummarize] = useState(true);
  const rcaId = useRecoilValue(selectedDatabaseIdState);
  const lockYear = useRecoilValue(selectedLockYearState);
  const isSupportedRca = rcaId < NATIONAL_DB_ID && rcaId !== WAKA_KOTAHI_DB_ID;

  const enabled = isSupportedRca && !isEmpty(lockYear);

  const {
    data, isLoading: isAssetsLoading, error,
  } = useAssets({ summarize, enabled });
  const { data: dataConfidenceScore, isLoading: isDCLoading } = useDataConfidence({ enabled });
  const groupedAssets = groupBy(data, 'label');
  const [selectedAsset, setSelectedAsset] = useState(null);
  const isLoading = isAssetsLoading || isDCLoading;

  const errorStatus = error?.response?.status;

  useEffect(() => {
    if (isEmpty(groupedAssets)) {
      return;
    }

    // Grouped assets have changed but we already have a selected asset
    if (selectedAsset && groupedAssets[selectedAsset.label]) {
      // Make sure to select the same asset if it exists in the new group
      // as the asset data may have changed (e.g. if we switch councils)
      const matchingAsset = groupedAssets[selectedAsset.label].find((a) => a.subType === selectedAsset.subType);
      setSelectedAsset(matchingAsset);
      return;
    }

    // No asset has been selected - default to selecting first asset
    const firstKey = Object.keys(groupedAssets)[0];
    setSelectedAsset(groupedAssets[firstKey][0]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupedAssets]);

  if (isLoading) {
    return (
      <MainLayout>
        <Container maxW="full">
          <LoadingSpinner />
        </Container>
      </MainLayout>
    );
  }

  if (errorStatus === 401 || errorStatus === 500) {
    return (
      <MainLayout>
        <Container maxW="full">
          <EmptyState
            title="Access Denied"
            message={(
              <>
                <Text color="gray.400">You do not have permission to view this page.</Text>
                <Text color="gray.400">Please sign in or select a different RCA.</Text>
              </>
            )}
          />
        </Container>
      </MainLayout>
    );
  }

  if (!enabled || isEmpty(groupedAssets)) {
    return (
      <MainLayout>
        <Container maxW="full">
          <EmptyState
            title="No Data"
            message="Sorry there is no data available for selected network"
          />
        </Container>
      </MainLayout>
    );
  }

  return (
    <MainLayout backgroundColor="gray.50">
      <Flex align="center" justify="space-between" px={4} pb={4}>
        <Heading as="h1">
          Asset Condition
        </Heading>
        <MoreDrawer>
          <Heading as="h3" fontSize="md" mb={4}>How to use the Asset Condition dashboard:</Heading>
          <Text mb={4}>This dashboard shows the overall condition of asset types within your network and the change in overall level of condition over the last five years. Further down the page you will also see the condition by ONF Street Family (Urban and Rural) and ONF Street Category.</Text>
          <Text mb={4}>Click on the top pie chart for each asset type to see the current condition and trend for the asset type.</Text>
          <Text mb={4}>The graphs show the summary of conditions (1 to 5 and U) that are recorded against the assets in each group. The graphs do not currently limit the condition results on how long ago the condition was updated.</Text>
          <Text>You can toggle between showing &ldquo;Good vs Poor&ldquo; and the individual conditions. When showing Good vs Poor, &ldquo;Good&ldquo; includes Excellent, Good and Average. &ldquo;Poor&ldquo; includes Poor and Very Poor.  </Text>
          <Heading as="h3" fontSize="md" mt={8}>Important Links</Heading>
          <List mt={3} spacing={3}>
            <ListItem><Link href="https://www.nzta.govt.nz/planning-and-investment/planning/transport-excellence-partnership/differential-levels-of-service/" target="_blank">Differential Levels of Service project</Link></ListItem>
            <ListItem><Link href="https://www.nzta.govt.nz/planning-and-investment/planning/one-network-framework/" target="_blank">One Network Framework</Link></ListItem>
          </List>
          {isSupportedRca && dataConfidenceScore > 0
            && (
            <Box>
              <Heading as="h3" fontSize="md" my={8}>Data Confidence</Heading>
              <Flex alignSelf="flex-start">
                <Flex direction="row" alignItems="center">
                  <GaugeChart
                    nrOfLevels={4}
                    arcPadding={0.025}
                    cornerRadius={2}
                    percent={dataConfidenceScore / 100}
                    colors={['#cccccc', '#999999', '#666666', '#333333']}
                    hideText
                    style={{ width: 150 }}
                  />
                  <Box>
                    <Text fontWeight="bold" fontSize="2xl">
                      {dataConfidenceScore}
                      %
                    </Text>
                  </Box>
                </Flex>
              </Flex>
            </Box>
            )}
        </MoreDrawer>
      </Flex>
      <VStack align="stretch" px={4}>
        <>
          <ConditionSelector
            summarize={summarize}
            setSummarize={setSummarize}
          />
          {selectedAsset
            && (
            <>
              <AssetSelector
                assets={groupedAssets}
                selectedAsset={selectedAsset}
                setSelectedAsset={setSelectedAsset}
              />
              <AssetInspector asset={selectedAsset} summarize={summarize} />
            </>
            )}
        </>
      </VStack>
    </MainLayout>
  );
}

export default LevelsOfService;
