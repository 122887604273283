import {
  Button,
  Icon,
} from '@chakra-ui/react';
import { FiLogIn } from 'react-icons/fi';

export default function UserMenu() {
  return (
    <Button
      leftIcon={<Icon as={FiLogIn} />}
      color="brand.orange.50"
      _hover={{
        color: 'brand.orange.500',
        backgroundColor: 'brand.orange.50',
      }}
      onClick={() => {
        window.location.href = process.env.REACT_APP_LOGIN_PATH;
      }}
      variant="ghost"
    >
      Sign In
    </Button>
  );
}
