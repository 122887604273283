import {
  Container,
  Heading,
  Text,
  useTheme,
} from '@chakra-ui/react';
import { isEmpty, groupBy } from 'lodash';
import Chart from 'react-apexcharts';
import { usePortalNavContext } from '@src/context/nav';
import MainLayout from '@src/components/layouts/main';
import { useReports } from '@src/modules/regional-reporting/context/reports';
import LoadingSpinner from '@src/components/shared/LoadingSpinner';
import ChartContainer from '@src/modules/onf/components/shared/ChartContainer';
import Pagination from '@src/modules/regional-reporting/components/Pagination';
import EmptyState from '@src/components/shared/EmptyState';
import InfoPane from '@src/modules/regional-reporting/components/InfoPane';
import { reportsList } from '@src/modules/regional-reporting/api/reports-list';

const sortingFreight = ['Same', 'InTo', 'OutOf'];

export default function Freight() {
  const theme = useTheme();
  const { isLoading } = usePortalNavContext();
  const { data: report, isLoading: isLoadingReport } = useReports('freight');
  const years = [...new Set(report?.data?.map((x) => x.fnYear))].sort();
  const regionName = report?.rcaName?.replace('Region', '');
  const { description, notification } = reportsList.find((x) => x.url === 'freight');

  const legendLabels = {
    Same: `Within ${regionName}`,
    InTo: `NZ to ${regionName}`,
    OutOf: `${regionName} to NZ`,
  };

  const railFreight = Object.entries(
    groupBy(report?.data?.sort((a, b) => sortingFreight.indexOf(a.type) - sortingFreight.indexOf(b.type)), 'type'),
  )
    .map(([k, v]) => ({
      name: legendLabels[k],
      data: v.map((item) => ({
        x: item.fnYear,
        y: item.value,
      })),
    }));

  if (isLoading || isLoadingReport) {
    return (
      <MainLayout>
        <Container maxW="full">
          <Pagination currentReportUrl="freight" reportData={report} isLoading />
          <LoadingSpinner />
        </Container>
      </MainLayout>
    );
  }

  if (isEmpty(report?.data) && report?.rcaName !== null) {
    return (
      <MainLayout>
        <Container maxW="full">
          <Pagination currentReportUrl="freight" reportData={report} isLoading={false} />
          <EmptyState title="No Data" message="Sorry there is no data available for selected region" />
        </Container>
      </MainLayout>
    );
  }

  if (!isEmpty(report?.data)) {
    return (
      <MainLayout>
        <Container maxW="full">
          <Pagination currentReportUrl="freight" reportData={report} isLoading={false} />
          {description
              && (
                description
              )}
          {notification
              && (
                <InfoPane>
                  <Text>{notification}</Text>
                </InfoPane>
              )}
          <Heading as="h2" mb={4} fontWeight="normal" fontSize="2xl">
            Rail freight movements to, from and within
            {' '}
            {regionName}
          </Heading>
          <ChartContainer>
            <Chart
              options={
                {
                  chart: {
                    stacked: true,
                    animations: {
                      enabled: false,
                    },
                    zoom: {
                      enabled: false,
                    },
                  },
                  colors: [theme.colors.green[600], theme.colors.brand.blue[300], theme.colors.brand.blue[700]],
                  dataLabels: {
                    enabled: false,
                  },
                  tooltip: {
                    enabled: true,
                    shared: true,
                    intersect: false,
                    inverseOrder: true,
                  },
                  xaxis: {
                    categories: years,
                  },
                  yaxis: [
                    {
                      title: {
                        text: 'Million-tonne kilometers freight moved',
                      },
                      min: 0,
                      tickAmount: 6,
                      labels: {
                        formatter(val) {
                          return val.toFixed(2);
                        },
                      },
                    },
                  ],
                  legend: {
                    onItemClick: {
                      toggleDataSeries: true,
                    },
                  },
                }
              }
              series={railFreight}
              width="100%"
              height="500"
              type="bar"
            />
          </ChartContainer>
        </Container>
      </MainLayout>
    );
  }

  // Not a region
  return (
    <MainLayout>
      <Container maxW="full">
        <EmptyState title="Not a Region" message="These reports only return data for regions." />
      </Container>
    </MainLayout>
  );
}
