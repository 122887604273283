import {
  Box, Grid, Heading, HStack, Spinner, Text, Tooltip, useDisclosure,
} from '@chakra-ui/react';
import {
  PollJobs,
} from '@src/modules/tce/src/context/jobs';
import { useTceRcaImports } from '@src/modules/tce/src/context/rcaimports';
import { councilSelectorEnabledState } from '@src/state/navContext';
import AccessWarningDialog from '@tce/components/pages/import/components/AccessWarningDialog';
import { useRcaAccess } from '@tce/context/access';
import { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { useSetRecoilState } from 'recoil';
import EstimateAction from './EstimateAction';
import EstimateStatusLabel from './EstimateStatusLabel';
import JobStatusBox from './JobStatusBox';

export default function ImportSelector() {
  const setEnableCouncilSelector = useSetRecoilState(councilSelectorEnabledState);
  const { isLoading, rcaImports } = useTceRcaImports();
  const queryClient = useQueryClient();
  const { data: hasAccess, accessLoading } = useRcaAccess();
  const { isOpen, onOpen, onClose } = useDisclosure();
  useEffect(() => setEnableCouncilSelector(true), [setEnableCouncilSelector]);

  const { jobs: statuses } = PollJobs();

  useEffect(() => {
    if (hasAccess === false) {
      onOpen();
    }
  });

  useEffect(() => {
    // update the job statuses
    rcaImports?.forEach((imp) => {
      if (imp?.job) {
        const p = statuses?.find((jobStatus) => jobStatus.id === imp.job.id);
        if (p) {
          if (imp.job.status !== p.status) {
            // refresh the data
            queryClient.invalidateQueries('tce-rcaimports');
            queryClient.invalidateQueries('tce-trafficgroups');
          }
          imp.job.status = p.status;
          imp.job.percentCompleted = p.percentCompleted || 0;
          imp.job.errorMessage = p.errorMessage || '';
        }
      }
    });
  }, [queryClient, rcaImports, statuses]);

  if (isLoading || accessLoading) {
    return (
      <Box>
        <Spinner />
      </Box>
    );
  }

  const onCancelDialog = () => {
    onClose();
    window.location.href = '/';
  };

  const getTooltip = (imp) => {
    if (['QUEUED', 'PREPARING', 'COPYING'].includes(imp?.job?.status)) {
      return 'The Traffic Counts and Estimates for this Import are being loaded into TCE. This will complete shortly.';
    }
    if (imp?.job?.status === 'FAILED') {
      return 'Transport Insights cannot complete the TCE calculation. Please contact the Transport Insights Support Desk';
    }
    return '';
  };

  return (
    <>
      {rcaImports?.length < 1 && (
        <Box py={10}>
          There are no imports for this RCA.
        </Box>
      )}
      {hasAccess === false && (
        <AccessWarningDialog isOpen={isOpen} onCancel={onCancelDialog} />
      )}
      {hasAccess === true && (
      <Grid templateColumns={{ md: 'repeat(2, 1fr)', lg: 'repeat(3, 1fr)', xl: 'repeat(4, 1fr)' }} gap={4} mt={4}>
        {rcaImports.length > 0 && rcaImports.map((imp) => (
          <Tooltip label={getTooltip(imp)} key={imp.id}>
            <Box backgroundColor="white" p={4} borderRadius="md" boxShadow="sm">
              <HStack justify="space-between">
                <Heading fontSize="2xl">{imp.lockYear}</Heading>
                <EstimateStatusLabel job={imp?.job} />
              </HStack>
              <Box mt={4}>
                <HStack justify="space-between">
                  <Text>RAMM Import</Text>
                  <Text fontWeight="bold">{imp.id}</Text>
                </HStack>
                <HStack mt={1} justify="space-between">
                  <Text>Completed</Text>
                  <Tooltip label={`${new Date(imp.completedDate).toDateString()} ${new Date(imp.completedDate).toLocaleTimeString('en-GB')}`} placement="top" hasArrow>
                    <Text fontWeight="bold">{new Date(imp.completedDate).toLocaleDateString('en-GB')}</Text>
                  </Tooltip>
                </HStack>
              </Box>
              <JobStatusBox job={imp.job} />
              <HStack justify="flex-end" align="center" mt={4}>
                <EstimateAction imp={imp} />
              </HStack>
            </Box>
          </Tooltip>
        ))}
      </Grid>
      )}
    </>
  );
}
