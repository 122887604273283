import {
  Image, Box, Flex, HStack,
} from '@chakra-ui/react';
import CornerFold from '@src/assets/images/fold.svg';
import { useConfig } from '@src/context/config';
import { useState } from 'react';
import HeaderLogo from './components/HeaderLogo';

export default function MainHeaderLayout({ children }) {
  const config = useConfig();
  const [isHidden, setIsHidden] = useState(false);

  return (
    <Box
      as="header"
      pos="fixed"
      w="full"
      h="80px"
      top={0}
      left={0}
      right={0}
      zIndex="500"
      backgroundColor="brand.orange.400"
    >
      <Box
        visibility={isHidden ? 'hidden' : 'visible'}
        onClick={() => setIsHidden(!isHidden)}
        cursor="pointer"
        background="gray.800"
        color="gray.50"
        fontSize="10px"
        fontWeight="bold"
        textTransform="uppercase"
        zIndex="200"
        pos="absolute"
        top="2px"
        right="2px"
        px={1}
        borderRadius="sm"
      >
        {config.isNonProd && `Env: ${config.stage}`}
      </Box>
      <Flex wrap="wrap" align="center" px={4} height="100%">
        <HeaderLogo width={{ base: 'auto', xl: '18rem' }} mr={{ base: '3', xl: '0' }} />
        <HStack flex={1}>
          { children }
        </HStack>
      </Flex>
      <Image src={CornerFold} alt="Fold" position="absolute" bottom="0" right="0" />
    </Box>
  );
}
