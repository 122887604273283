import { useQuery } from 'react-query';
import { useRecoilValue } from 'recoil';
import { currentDatabaseSelector } from '@src/state/navContext';
import useTceApi from '../api/useTceApi';

export function useTceRcaImports() {
  const rca = useRecoilValue(currentDatabaseSelector);
  const api = useTceApi();

  const { isLoading, data: rcaImports, error } = useQuery(
    ['tce-rcaimports', rca?.id],
    async () => api.getRcaImports(rca?.id),
    {
      enabled: rca?.id !== null,
    },
  );

  return {
    isLoading,
    rcaImports,
    error,
  };
}
