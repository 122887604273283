import { useEffect, useState, forwardRef } from 'react';
import { Box, Center, Text } from '@chakra-ui/react';
import LockYearBadge from '@src/modules/performance/components/LockYearBadge';
import LineChart from '../charts/LineChart';
import NoDataChart from '../charts/NoDataChart';

// eslint-disable-next-line react/display-name
const SLLineChart = forwardRef(({
  queryInfo,
  title1,
  title2,
  labels,
  colours,
  unitprefix,
  unitpostfix,
  ...props
}, ref) => {
  const [chart, setChart] = useState({
    grouping: [],
    datasets: [],
    labels,
    nodata: false,
  });

  useEffect(() => {
    if (queryInfo?.data) {
      const newChart = {
        grouping: [],
        datasets: [],
        labels,
        nodata: false,
      };
      const { data } = queryInfo;
      if (!data) {
        newChart.nodata = true;
      } else {
        newChart.grouping = data.years;
        newChart.datasets = data.values;
        newChart.labels = labels;
        newChart.nodata = data.nodata || false;

        if (labels.length < 1 && data.categories) {
          newChart.labels = data.categories;
        }
      }
      setChart(newChart);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryInfo]);

  if (!queryInfo?.data) {
    return null;
  }

  return (
    <Box>
      <Text align="center" fontSize={12} fontWeight="bold">{title1}</Text>
      <Text align="center" fontSize={12} fontWeight="bold" mb={5}>{title2}</Text>
      <LockYearBadge lockYear={queryInfo?.data?.lockYear} />
      <Center>
        {chart.nodata && (
          <NoDataChart {...props} />
        )}
        {!chart.nodata && (
        <LineChart
          grouping={chart.grouping}
          labels={chart.labels}
          datasets={chart.datasets}
          colours={colours}
          unitprefix={unitprefix}
          unitpostfix={unitpostfix}
          ref={ref}
          {...props}
        />
        )}
      </Center>
    </Box>
  );
});

export default SLLineChart;
