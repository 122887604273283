import { useState, useEffect } from 'react';
import { useSafetyKPI } from '@src/modules/performance/context/map-api-hooks';
import TextWidget from './TextWidget';

function SafetyWidget({ ...props }) {
  const { data: safety, isLoading } = useSafetyKPI();
  const [value, setValue] = useState('--');
  const [colour, setColour] = useState('GREY');
  const [marker, setMarker] = useState('none');

  useEffect(() => {
    setColour(safety.colour);
    setValue(safety.value || 'No data');
    setMarker(safety.marker);
  }, [safety]);

  return (
    <TextWidget
      title="Safety"
      value={value}
      lockYear={safety.lockYear}
      subtext1="Fatal & Serious Injuries/Trend"
      subtext2={`(${safety.averageYearRange} Annual Avg)`}
      link="/performance/dashboard/safety"
      colour={colour}
      marker={marker}
      loading={isLoading}
      fontSize={value !== 'No data' ? 48 : 32}
      {...props}
    />
  );
}

export default SafetyWidget;
