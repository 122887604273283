/* eslint-disable max-len */
import {
  Text as ChakraText, Tooltip as ChakraTooltip, Spinner, ListItem, OrderedList,
} from '@chakra-ui/react';
import { useGlossary } from '@src/modules/onf/context/glossary';

function Text({ children, ...props }) {
  return <ChakraText {...props} mb={2}>{children}</ChakraText>;
}

function Tooltip({ children, ...props }) {
  return <Text color="gray.400" fontWeight="bold" as="span" borderBottom="1px dotted"><ChakraTooltip hasArrow {...props}>{children}</ChakraTooltip></Text>;
}

export default function Content({ reportId }) {
  const { data, error, isLoading } = useGlossary();

  if (isLoading) return <Spinner />;

  // const FnYear = '';
  let PeerGroup = '';
  let Region = '';
  let Nation = '';

  if (!isLoading && data && !error) {
    // FnYear = <Tooltip label={data.find((x) => x.code === 'FnYear').message}>Financial Year</Tooltip>;
    PeerGroup = <Tooltip label={data.find((x) => x.code === 'PeerGroup').message}>Peer Group</Tooltip>;
    Region = <Tooltip label={data.find((x) => x.code === 'Region').message}>Region</Tooltip>;
    Nation = <Tooltip label={data.find((x) => x.code === 'Nation').message}>Nation</Tooltip>;
  }

  switch (reportId) {
    case 'hsp1':
      return (
        <>
          <Text>The aim of this measure is to ensure that roads and roadsides are becoming safer for road users.</Text>
          <Text>The injuries and fatalities data comes from the Crash Analysis System (CAS). Crashes are allocated to roads based on the location of the crash.</Text>
          <Text>The classifications are from the One Network Framework classification hierarchy.</Text>
          <Text>The data is grouped by Financial Year (July to June) or Calendar Year (January to December). Calendar and Financial Years are considered complete once the crash data is imported at the end of the financial year.</Text>
          <Text>
            The comparative trend graph at the bottom of the page expresses the serious injuries and fatalities trend as a percentage of the average for the five calendar/financial years. This provides a comparable view of the rate of progress in reducing serious injuries and fatalities. This trend is shown for your network,
            {' '}
            {PeerGroup}
            ,
            {' '}
            {Region}
            {' '}
            and the
            {' '}
            {Nation}
            . Negative values, shown in green, indicating a decreasing or improving trend. Positive values, shown in red, indicate an increasing or worsening trend.
          </Text>
        </>
      );
    case 'hsp2':
      return (
        <>
          <Text>The aim of these measures is to ensure that roads and roadsides are becoming safer for road users.</Text>
          <Text>
            The page determines the number of crashes with reported Fatal and Severe Injuries from the Crash data for the previous 10 calendar/financial years on your network,
            {' '}
            {PeerGroup}
            ,
            {' '}
            {Region}
            {' '}
            and the
            {' '}
            {Nation}
            .
          </Text>
          <Text>Crashes are allocated to road sections based on the location of the crash. If a crash is located at the start of one road section and the end of another section, it will be allocated to the section which starts where the crash is located.</Text>
          <Text>The number of crashes with serious injuries and fatalities on the network within the classification is divided by the number of years of data (the difference between the date of the first crash in the classification and the last up to a maximum of 10 years) to get the average number per year.</Text>
          <Text>For the Personal Risk measure, the average count is then divided by the vehicle kilometres travelled (VKT) across the network. VKT (metres) is the sum of [section length (in metres) * average daily traffic (ADT) estimate * 365] for all road sections in the network. The VKT (m) total is divided by 100,000,000 to get 100M VKT.</Text>
          <Text>For the Collective Risk measure, the average count is then divided by the network length and reported per 1,000km</Text>
        </>
      );
    case 'hsp3':
      return (
        <>
          <Text>The aim of this measure is to look for a reducing trend in the number of serious injuries and fatalities in crashes on wet roads, at night, or at intersections. </Text>
          <Text>
            Reported crashes are recorded in the Crash Analysis System (CAS) with indicators of which crashes involve the different situations.
          </Text>
          <Text>
            The page groups the data for the last 5 complete Financial Year (July to June) or Calendar Year (January to December). Calendar and Financial Years are considered complete once the crash data is imported at the end of the financial year.
          </Text>
          <Text>
            Crashes are allocated to road section(s) based on the location of the crash. If a crash is located at the start of one road section and the end of another road section, it will be allocated to the section of road section which starts where the crash is located.
          </Text>
          <Text>
            The comparative trend graph expresses the trend as a percentage of the annual average counts in the situation for the last five calendar/financial years. This trend is shown calculated for your network,
            {' '}
            {PeerGroup}
            ,
            {' '}
            {Region}
            {' '}
            and the
            {' '}
            {Nation}
            . Negative values, shown in green, indicating a decreasing or improving trend. Positive values, shown in red, indicate an increasing or worsening trend.
          </Text>
          <Text>You can also select other networks to compare your trends against.</Text>
        </>
      );
    case 'hsp4':
      return (
        <>
          <Text>The aim of this measure is to look for a reducing trend in the number of serious injuries and fatalities in crashes involving vulnerable road users. </Text>
          <Text>
            Reported crashes are recorded in the Crash Analysis System (CAS) with indicators of which crashes involve the different types of vulnerable users.
          </Text>
          <Text>
            The Vulnerable User page groups the data for the last 5 complete Financial Year (July to June) or Calendar Year (January to December). Calendar and Financial Years are considered complete once the crash data is imported at the end of the financial year.
          </Text>
          <Text>
            Crashes are allocated to road section(s) based on the location of the crash. If a crash is located at the start of one road section and the end of another road section, it will be allocated to the section of road section which starts where the crash is located.
          </Text>
          <Text>
            The comparative trend graph expresses the vulnerable users trend as a percentage of the average crash rate for the last five calendar/financial years.  This trend is shown calculated for your network,
            {' '}
            {PeerGroup}
            ,
            {' '}
            {Region}
            {' '}
            and the
            {' '}
            {Nation}
            . Negative values, shown in green, indicating a decreasing or improving trend. Positive values, shown in red, indicate an increasing or worsening trend.
          </Text>
          <Text>
            The Crash Analysis System (CAS) records which vulnerable types are involved in a crash but does not record the specific counts of DSI for each vulnerable type.
            The measure therefore shows the counts of Crashes involving deaths and/or serious injuries to vulnerable users. A crash can involve multiple types.
          </Text>
          <Text>You can also select other networks to compare your trends against.</Text>
        </>
      );
    case 'hsp5':
      return (
        <>
          <Text>The aim of this measure is to aid RCAs with their strategies to identify and manage higher risk sites, so they can minimise the consequences of crashes by providing forgiving roads and roadsides.</Text>
          <Text>
            The Infrastructure Risk Rating (IRR) Bands data for each RCA comes from Waka Kotahi’s Megamaps mapping system. The data for 2021/22 was downloaded from Megamaps in January 2023. Data for some RCAs was not available in Megamaps at the time so any
            {' '}
            {Region}
            ,
            {' '}
            {PeerGroup}
            {' '}
            or
            {' '}
            {Nation}
            {' '}
            percentages shown may not be complete.
          </Text>
          <Text>
            The 10-year crash count data comes from the Crash Analysis System (CAS). Crashes are allocated to roads based on the location of the crash. Crash data is grouped by Financial Year beginning 1 July. Financial years are considered complete once the crash data is imported at the end of the financial year.
          </Text>
          <Text>
            The classifications are from the One Network Framework classification hierarchy.
          </Text>
          <Text>
            There is no historical IRR Band data available at this time so there is no trend reporting available..
          </Text>
          <Text color="red">
            The data used in this page is sourced from Megamaps and is potentially out of date. Please treat all results on this page as indicative only.
          </Text>
        </>
      );
    case 'ia1':
      return (
        <>
          <Text>The aim of this measure is to display the smoothness of the journeys experienced by people using the network.</Text>
          <Text>
            STE is the percentage of travel on the network that is smoother than a set of smoothness thresholds, reported by One Network Framework (ONF) Street Category, for your network,
            {' '}
            {PeerGroup}
            ,
            {' '}
            {Region}
            {' '}
            and the
            {' '}
            {Nation}
            .
          </Text>
          <Text>This page currently calculates percentage of Smooth Travel Exposure (STE) as per RAMM and ONRC, using the Vehicles per Day (VPD) bands currently implemented in RAMM together with their NAASRA thresholds. The VPD ranges and thresholds are available at the bottom of the page of graphs.</Text>
          <Text color="red">
            You also have an option to see STE results using thresholds defined for ONF Movement Classes (M1 - M5 for Urban and Rural).
            {' '}
            <Text fontWeight="bold" as="span" color="red">You should not use the ONF Movement Class results for any regulatory reporting at this time.</Text>
            {' '}
            The table of ONF Movement thresholds will show at the bottom of the page when you select the ONF Movement Classes option.
          </Text>
          <Text fontWeight="bold">Calculating smooth lengths by ONF Road Section</Text>
          <Text>The ONF Road Section calculation will locate the roughness (HSD else 100m roughness) readings for all ONF road section records in the network. The calculation will use the roughness (HSD else 100m roughness) readings that have the latest reading dates.</Text>
          <Text>The calculation will identify the length of each ONF road section that is surveyed and the length of the section that is smooth. It is anticipated that Road Controlling Authorities (RCAs) take roughness readings across their entire network every two years, and any gaps are presumed to be unreadable.</Text>
          <Text>If a roughness record overlaps more than one road section, the length of the roughness record will be apportioned to each road section it overlaps. For the purposes of the calculation, the roughness record will effectively be split on each ONF road section boundary. When a roughness record is apportioned across multiple ONF road sections, each road section-portion will retain the NAASRA value of the roughness record.</Text>
          <Text>Once the smooth length and survey length have been calculated for each ONF road section, STE percentages will be calculated per ONF Street Category using the total smooth lengths and survey lengths, weighted by VKT.</Text>
          <Text fontWeight="bold">VKT Weighting</Text>
          <Text>The Vehicle Kilometres Travelled (VKT) for the Survey and Smooth lengths of each ONF Road Section is calculated using the formula: (length / 1000) * average daily traffic (ADT) estimate  * 365.</Text>
          <Text>The Smooth Travel percentages are calculated as the total Smooth VKT / the total Survey VKT, for each ONF Street Category.</Text>
        </>
      );
    case 'ia2':
      return (
        <>
          <Text>The aim of this measure is to demonstrate the smoothness/roughness of the journey on sealed roads in the network.</Text>
          <Text>
            &quot;NAASRA Roughness Counts&quot; (NRC) is the description of ride quality defined by the movement of a point on the body of a vehicle in relation to its rear axle while travelling at a predetermined speed. The higher the NRC, the rougher the ride quality will be.
          </Text>
          <Text>You can choose to show the 75th percentile, 85th percentile, or 95th percentile of NRC. The graphs will show the NAASRA Roughness Count which that percentage of sealed roads are at or lower. E.g. 75% of the roads in an ONF category in the network are at a NAASRA roughness count of 106 or lower.</Text>
          <Text>
            The comparison graphs by default show your network,
            {' '}
            {PeerGroup}
            ,
            {' '}
            {Region}
            , and the
            {' '}
            {Nation}
            . You can select other networks to compare your network against.
          </Text>
        </>
      );
    case 'ep1':
      return (
        <>
          <Text>This page reports on unplanned road closures that are recorded in the Road Closures and Restrictions menu option in RAMM.</Text>
          <Text>
            The aim of the measure is to minimise the impact on journeys from unplanned road closures.
          </Text>
          <Text>The ONF Road Closures page shows:</Text>
          <OrderedList mb={3}>
            <ListItem>the number of unplanned road closures with a detour provided, or </ListItem>
            <ListItem>the number of unplanned road closures where there is no detour available.</ListItem>
          </OrderedList>
          <Text>For the option you choose, the page shows the number of closures in the year and the estimated number of journeys affected by those closures.</Text>
          <Text>
            Closures are allocated to roads within the Road Closures and Restrictions menu item in RAMM. Closures are allocated to ONF Street Categories based on the category of the road section where the closure is located.
          </Text>
          <Text>The ONF Street Categories are from the One Network Framework classification hierarchy.</Text>
        </>
      );
    case 'ep2':
      return (
        <>
          <Text>This page reports on heavy vehicle restrictions that are recorded in the Road Closures and Restrictions UDT option in RAMM.</Text>
          <Text>
            The aim of this measure is that heavy trucks are able to move goods without undue restriction by measuring the proportion of network not available to heavy vehicles.
          </Text>
          <Text>This measure shows the percentage of network in each ONF Street Category that is not accessible to Class 1 Heavy vehicles, HPMV rated vehicles, or 50MAX vehicles.</Text>
          <Text>
            Road Controlling Authorities (RCAs) will investigate the accessibility restrictions to consider if there are any alternative routes around the restrictions or if there are economically practicable alternatives.
          </Text>
          <Text>Heavy vehicle restrictions are allocated to roads within the Road Closures and Restrictions user defined table in RAMM. Restrictions are allocated to ONF Street Categories based on the category of the road section where the restriction is located.</Text>
          <Text>
            The ONF Street Categories are from the One Network Framework classification hierarchy.
          </Text>
        </>
      );
    case 'ep3':
      return (
        <>
          <Text>The aim of this measure is to demonstrate that our service cost is affordable and consistent across New Zealand. Variations in service cost are understood and we are undertaking improvements to address variations and innovating to drive value for money. </Text>
          <Text>
            This measure reports on Maintenance Costs associated with the Pavement Cost (PA), Surfacing (SU), Shoulder (SH), Bridge (BR), and Drainage (DR) Cost Groups. The report sums the costs by Cost Group.
          </Text>
          <Text>
            Any non-maintenance costs (such as renewals) that are recorded against cost groups BR, DR, PA, SH and SU will be included in this report.
          </Text>
          <Text>
            Lane Kilometres is calculated from the road section lengths (in metres) / 1000 * the number of lanes on the road section.
          </Text>
          <Text>
            Vehicle Kilometres Travelled (VKT) is calculated as the road section length (m) / 1000 * the traffic estimated volume per day * 365.
          </Text>
          <Text>
            Maintenance Costs are apportioned pro-rata by length to Road Sections when the displacement of the Maintenance Cost record overlaps the displacement of the road section.
          </Text>
          <Text>
            The measure will only report on maintenance costs associated to road section(s) that are One Network Framework (ONF) classified and are owned by the RCA.
          </Text>
          <Text>
            The data is grouped by Financial Year beginning 1 July.
          </Text>
          <Text>
            The graphs show the common cost group descriptions used by most RCAs. Currently some RCAs have customised descriptions for the cost group codes BR, DR, PA, SH and SU to reflect their specific use. However the common terms are shown because we are comparing different RCAs and summarising by
            {' '}
            {PeerGroup}
            ,
            {' '}
            {Region}
            , and the
            {' '}
            {Nation}
            .
          </Text>
          <Text>
            If this Performance Measure is not returning any results, first check your Maintenance Costs for the year in Road Assessment and Maintenance Management (RAMM). This measure looks for Maintenance Costs. Use RAMM to access the Maintenance Costs and filter for records associated with the Cost Groups PA, SU, SH, BR, and DR. Maintenance Costs are typically imported from files received from your consultants or contractors or may be transferred from paid invoices recorded in RAMM Contractor.
          </Text>
        </>
      );
    case 'ep4':
      return (
        <>
          <Text>
            The aim of this measure is to show the length, area, cost, and average life, of each of sealed road pavement rehabilitation / chipseal resurfacing / asphalt resurfacing undertaken on the network over the selected year. Sealed pavement rehabilitations and resurfacing should be timed to minimise whole-of-life cost while delivering the required customer outcomes. This measure provides information that can assist with demonstrating the extent to which this has been achieved.
          </Text>
          <Text>
            <Text fontWeight="bold" as="span">Pavement rehabilitation</Text>
            {' '}
            totals are identified from Membrane Seal or First Coat surfaces with a Work Category of 214. Surface records will be assigned a Work Category of 214 when the resurfacing is due to pavement rehabilitation. Reseal and second coat surfaces are excluded to avoid double-counting the surface length and area if the pavement rehabilitation is resealed at a later date.
          </Text>
          <Text>
            <Text fontWeight="bold" as="span">Chipseal and asphalt resurfacing</Text>
            {' '}
            totals are identified from reseals and second coats of top surface records with a Work Category of 212.
          </Text>
          <Text>
            The surface data comes from the Road Section Surface table in RAMM.
          </Text>
          <Text>
            <Text fontWeight="bold" as="span">Lane Kilometre Length</Text>
            {' '}
            is calculated from the road section lengths (in metres) / 1000 * the number of lanes on the road section.
          </Text>
          <Text>
            <Text fontWeight="bold" as="span">Area</Text>
            {' '}
            is calculated from the Sealed Area for the surface data recorded as pavement rehabilitation (Work Category 214) or resurfacing (Work Category 212). If the Sealed Area is recorded then this will be used. The Sealed Area may or may not reflect the Width or Length of the Road Section. If the Sealed Area is not recorded, then it will be assumed that the Seal is for the full width of the Road Section and the Area of the Seal will be calculated from the Seal Length * Road Section Width.
          </Text>
          <Text>
            <Text fontWeight="bold" as="span">Original Cost</Text>
            {' '}
            is recorded on the Road Section Surface records and is apportioned by length for the Seal record in the Surface Structure table.
          </Text>
          <Text>
            <Text fontWeight="bold" as="span">Average Life per ONF Category</Text>
            {' '}
            is the average age of surfaces/pavement that were resurfaced/rehabilitated over the previous four years, across all treatment lengths in each ONF category.
          </Text>
          <Text>
            Vehicle Kilometres Travelled (VKT) is calculated as the road section length (m) / 1000 * the traffic estimated volume per day * 365.
          </Text>
          <Text>
            The data is grouped by Financial Year beginning 1 July.
          </Text>
          <Text>
            The measure will only report on rehabilitation/resurfacing associated to road sections that are One Network Framework (ONF) classified and are owned by the RCA. Values are apportioned pro-rata by length to ONF categories when the displacement of the surface record overlaps the displacement of the ONF road section.
          </Text>
          <Text>
            The comparative graph shows the percentage of rehabilitation/resurfacing  for the selected year over the total lane kms, for your network,
            {' '}
            {' '}
            {PeerGroup}
            ,
            {' '}
            {Region}
            , and the
            {' '}
            {Nation}
            . You can choose different networks to compare to.
          </Text>
          <Text>
            If this Performance Measure is not returning any results, check your rehabilitation/resurfacing records for the year in RAMM.
          </Text>
        </>
      );
    default:
      return null;
  }
}
