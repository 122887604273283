import { useEffect, useState } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Heading,
  useDisclosure,
  Text,
  Box,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Tag,
  TagLabel,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Flex,
  VStack,
  TagCloseButton,
  Checkbox,
  HStack,
  InputGroup,
  InputLeftElement,
  Input,
  Icon,
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  PopoverAnchor,
  Spinner,
} from '@chakra-ui/react';
import {
  FiSearch, FiTrash2, FiAlertCircle, FiInfo, FiCheck,
} from 'react-icons/fi';
import { formatDate } from '@src/utils/dates';
import HnoFilter from './HnoFilter';

// Component for the Network item in a list
function NetworkItem({ children, ...props }) {
  return (
    <Flex
      px={3}
      py={2}
      mt="0!important"
      width="100%"
      {...props}
      borderColor="gray.100"
    >
      {children}
    </Flex>
  );
}

// Component for scrollable list
function ScrollableList({ children }) {
  return <VStack align="flex-start" border="1px solid" borderColor="gray.100" borderRadius="md" height="0px" flexGrow="1" overflowY="scroll">{children}</VStack>;
}

export default function NetworkSelector({
  currentNetworks, currentHnos, lockYear, data, isLoading, callback,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const selectionLimit = 7;
  const [selectedNetworks, setSelectedNetworks] = useState([]);
  const [selectedHnos, setSelectedHnos] = useState([]);
  const [filteredRcas, setFilteredRcas] = useState([]);
  const [rcas, setRcas] = useState([]);
  const [groups, setGroups] = useState([]);
  const regions = groups.filter((x) => !x.peerGroup).sort((a, b) => a.name.localeCompare(b.name))
    // Make sure National is first
    .sort((a, b) => {
      if (a.id === 1000) {
        return -1;
      }
      if (b.id === 1000) {
        return 1;
      }
      return 0;
    });
  const peerGroups = groups.filter((x) => x.peerGroup).sort((a, b) => a.name.localeCompare(b.name));
  const selectionLimitReached = selectedNetworks.length === selectionLimit;

  const filterList = (value) => {
    if (value !== '') {
      setFilteredRcas(rcas.filter((x) => x.name?.toLowerCase().startsWith(value.trim().toLowerCase())));
    } else {
      setFilteredRcas(rcas);
    }
  };

  const addAllRcas = (items) => {
    // Make sure we aren't adding rcas already selected
    setSelectedNetworks([...selectedNetworks, ...items.filter((rca) => !selectedNetworks.some((x) => x.id === rca.id))]);
  };

  // Show current selected networks
  useEffect(() => {
    if (data && rcas.length > 0 && groups.length > 0) {
      setSelectedNetworks([
        ...currentNetworks.filter((x) => x < 1000).map((n) => rcas.find((r) => n === r.id)),
        ...currentNetworks.filter((x) => x >= 1000).map((n) => groups.find((g) => n === g.id)),
        ...currentHnos.map((hno) => ({ id: hno, name: hno.filterName })),
      ]);
      setSelectedHnos([...selectedHnos, ...currentHnos]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, rcas, groups]);

  useEffect(() => {
    if (data) {
      setRcas(data.rcas);
      setGroups(data.groups);
      setFilteredRcas(rcas);
    }
  }, [rcas, data]);

  const handleHnos = (hno) => {
    const canAdd = !selectedHnos.some((x) => x.filterName === hno.filterName);
    if (canAdd && (selectedNetworks.length < selectionLimit)) {
      setSelectedHnos([...selectedHnos, hno]);
      setSelectedNetworks([...selectedNetworks, { id: hno, name: hno.filterName }]);
    }
  };

  const handleDeleteHno = (hno) => {
    setSelectedNetworks(selectedNetworks.filter((y) => y.id !== hno));
    setSelectedHnos(selectedHnos.filter((y) => y.filterName !== hno.filterName));
  };

  const handleCancel = () => {
    setSelectedNetworks([
      ...currentNetworks.filter((x) => x < 1000).map((n) => rcas.find((r) => n === r.id)),
      ...currentNetworks.filter((x) => x >= 1000).map((n) => groups.find((g) => n === g.id)),
    ]);
    onClose();
  };

  const handleCompare = () => {
    callback(selectedNetworks.map((x) => x.id));
    onClose();
  };

  useEffect(() => {
    if (selectedNetworks.length > selectionLimit) {
      setSelectedNetworks([...selectedNetworks.slice(0, selectionLimit)]);
    }
  }, [selectedNetworks]);

  if (Object.values(selectedNetworks).some((x) => x === undefined)) {
    return null;
  }

  return (
    <>
      <Heading mt={4} fontSize="3xl">Comparative Charts</Heading>
      <Flex
        width="100%"
        justify="space-between"
        align="flex-start"
        border="1px solid"
        borderColor="gray.100"
        borderRadius="md"
        boxShadow="sm"
        p={3}
        mt={3}
      >
        {isLoading && <Spinner /> }
        {!isLoading
          && (
          <>
            <Text wrap="wrap">
              <Text fontWeight="bold" as="span" fontSize="xs">
                Selected networks for comparison:
                <br />
              </Text>
              {selectedNetworks.length > 0 ? Object.values(selectedNetworks).map((x) => x.name).join(', ') : 'None'}
              <Text fontSize="xs" fontStyle="italic" as="span" display="block">Networks chosen may not show if they have no data for the selections in this report.</Text>
            </Text>
            <Button onClick={onOpen} size="md">Select other networks to compare</Button>
          </>
          )}
      </Flex>
      <Modal isOpen={isOpen} onClose={onClose} size="full">
        <ModalOverlay
          bg="blackAlpha.700"
          backdropFilter="blur(4px)"
        />
        <ModalContent>
          <ModalHeader>
            Select Networks for
            {' '}
            {lockYear}
          </ModalHeader>
          <ModalCloseButton onClick={handleCancel} />
          <ModalBody p={0} flex="1" flexDirection="column" display="flex">
            <Flex bg="gray.50" p={6} borderY="1px solid" borderColor="gray.100" flexDirection="column">
              <HStack align="center" mb={3} spacing={4}>
                <Text fontWeight="bold">Selected Networks</Text>
                {selectedNetworks.length > 0
                  && <Button variant="link" size="sm" onClick={() => setSelectedNetworks([])}>Clear Selection</Button>}
              </HStack>
              <Flex direction="row" wrap="wrap">
                {selectedNetworks.length === 0
                  && <Text>No networks selected</Text>}
                {selectedNetworks.map((network, i) => (
                  <Tag
                    key={`network_${i}`}
                    my={1}
                    mr={2}
                    size="lg"
                    borderRadius="full"
                    variant="solid"
                    colorScheme="brand.blue"
                  >
                    <TagLabel>{network.name}</TagLabel>
                    <TagCloseButton onClick={() => (typeof (network.id) === 'number'
                      ? setSelectedNetworks(selectedNetworks.filter((y) => y.id !== network.id))
                      : handleDeleteHno(network.id))}
                    />
                  </Tag>
                ))}
              </Flex>
              {selectionLimitReached && <Text mt={4} fontSize="sm">You have selected the maximum number of networks allowed.</Text>}
            </Flex>
            <Flex p={6} flexGrow="1">
              <Tabs variant="enclosed" colorScheme="gray" w="100%" display="flex" flexDirection="column">
                <TabList mb={8}>
                  <Tab>Search for an RCA</Tab>
                  <Tab>Waka Kotahi</Tab>
                  <Tab>Regions</Tab>
                  <Tab>Peer Groups</Tab>
                </TabList>
                <TabPanels display="flex" flexGrow="1">
                  <TabPanel p={0} display="flex" flexDirection="column" width="100%">
                    <InputGroup mb={4}>
                      <InputLeftElement pointerEvents="none"><Icon as={FiSearch} color="gray.300" /></InputLeftElement>
                      <Input type="text" placeholder="Start typing to find an RCA..." onChange={(e) => filterList(e.currentTarget.value)} />
                    </InputGroup>
                    <ScrollableList>
                      {filteredRcas.map((rca, i) => (
                        <NetworkItem
                          key={`network_${rca.id}`}
                          borderBottom={i < filteredRcas.length - 1 ? '1px solid' : 'none'}
                        >
                          {rca.hasData && rca.hasImported
                          && (
                          <Flex align="center" justify="space-between" w="100%">
                            <Checkbox
                              isChecked={selectedNetworks.some((y) => y.id === rca.id)}
                              onChange={() => (
                                selectedNetworks.some((y) => y.id === rca.id)
                                  ? setSelectedNetworks(selectedNetworks.filter((y) => y.id !== rca.id))
                                  : setSelectedNetworks([...selectedNetworks, rca])
                              )}
                            >
                              {rca.name}
                            </Checkbox>
                            <Text m={0}>
                              Last Import:
                              <Text fontWeight="bold" as="span">{formatDate(rca.lastImportDate)}</Text>
                            </Text>
                          </Flex>
                          )}
                          {!rca.hasData && !rca.hasImported
                          && (
                          <Flex align="center" justify="space-between" w="100%">
                            <Flex align="center">
                              <Icon as={FiAlertCircle} mr={2} />
                              <Text m={0} color="gray.400">{rca.name}</Text>
                            </Flex>
                            <Text m={0}>No Import</Text>
                          </Flex>
                          )}
                        </NetworkItem>
                      ))}
                    </ScrollableList>
                  </TabPanel>
                  <TabPanel p={0} display="flex" flexDirection="column" width="100%">
                    <Box border="1px solid" borderColor="gray.100" p={3} borderRadius="md">
                      <HnoFilter selectedHno={handleHnos} data={data?.hnos} buttonLabel="Add" />
                    </Box>
                    {selectedHnos.length > 0
                      && (
                      <Box mt={4}>
                        <Text fontWeight="bold" mb={4}>Waka Kotahi Networks to Include:</Text>
                        <Table variant="simple" size="sm" border="1px solid" borderColor="gray.100">
                          <Thead>
                            <Tr>
                              <Th>
                                Corridor
                              </Th>
                              <Th>
                                Regional Council
                              </Th>
                              <Th>
                                District Council
                              </Th>
                              <Th>
                                NOC
                              </Th>
                              <Th>&nbsp;</Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {selectedHnos.map((hno, i) => (
                              <Tr key={`hno_${i}`}>
                                <Td>{hno.corridorName || 'All'}</Td>
                                <Td>{hno.regionName || 'All'}</Td>
                                <Td>{hno.tlaName || 'All'}</Td>
                                <Td>{hno.noc || 'All'}</Td>
                                <Td textAlign="right">
                                  <IconButton onClick={() => handleDeleteHno(hno)} icon={<Icon as={FiTrash2} boxSize={6} />} colorScheme="red" variant="ghost" />
                                </Td>
                              </Tr>
                            ))}
                          </Tbody>
                        </Table>
                      </Box>
                      )}
                  </TabPanel>
                  <TabPanel p={0} display="flex" flexDirection="column" width="100%">
                    <ScrollableList>
                      {regions.map((region, i) => (
                        <NetworkItem
                          key={`region_${region.id}`}
                          borderBottom={i < regions.length - 1 ? '1px solid' : 'none'}
                          justify="space-between"
                        >
                          <Checkbox
                            isChecked={selectedNetworks.some((y) => y.id === region.id)}
                            onChange={() => (
                              selectedNetworks.includes(region)
                                ? setSelectedNetworks(selectedNetworks.filter((y) => y.id !== region.id))
                                : setSelectedNetworks([...selectedNetworks, region])
                            )}
                          >
                            {region.name}
                          </Checkbox>
                          {region.id !== 1000
                          && (
                          <Flex align="center">
                            <Popover placement="bottom" matchWidth="true">
                              <HStack>
                                <PopoverAnchor>
                                  <Button size="sm" colorScheme="gray" onClick={() => addAllRcas(region.rcas.filter((x) => x.hasImported && x.hasData))}>
                                    Add all
                                    {' '}
                                    {region.name}
                                    {' '}
                                    RCAs
                                  </Button>
                                </PopoverAnchor>
                                <PopoverTrigger>
                                  <IconButton
                                    variant="ghost"
                                    colorScheme="brand.blue"
                                    size="sm"
                                    ml={3}
                                    icon={<Icon as={FiInfo} boxSize={5} />}
                                  />
                                </PopoverTrigger>
                              </HStack>
                              <PopoverContent>
                                <PopoverArrow />
                                <PopoverBody>
                                  {region.rcas.map((rca) => ((!rca?.hasData && !rca?.hasImported) ? (
                                    <Flex key={`rca_${rca.id}`} align="center">
                                      <Icon as={FiAlertCircle} mr={2} />
                                      <Text m={0} color="gray.400">{rca.name}</Text>
                                    </Flex>
                                  ) : (
                                    <Flex key={`rca_${rca.id}`} align="center">
                                      <Icon as={FiCheck} mr={2} color="green.600" />
                                      <Text m={0}>{rca.name}</Text>
                                    </Flex>
                                  )))}
                                </PopoverBody>
                              </PopoverContent>
                            </Popover>
                          </Flex>
                          )}
                        </NetworkItem>
                      ))}
                    </ScrollableList>
                  </TabPanel>
                  <TabPanel p={0} display="flex" flexDirection="column" width="100%">
                    <ScrollableList>
                      {peerGroups.map((peerGroup, i) => (
                        <NetworkItem
                          key={`peerGroup${peerGroup.id}`}
                          borderBottom={i < regions.length - 1 ? '1px solid' : 'none'}
                          justify="space-between"
                        >
                          <Checkbox
                            isChecked={selectedNetworks.some((y) => y.id === peerGroup.id)}
                            onChange={() => (
                              selectedNetworks.includes(peerGroup)
                                ? setSelectedNetworks(selectedNetworks.filter((y) => y.id !== peerGroup.id))
                                : setSelectedNetworks([...selectedNetworks, peerGroup])
                            )}
                          >
                            {peerGroup.name}
                          </Checkbox>
                          <Flex align="center">
                            <Popover placement="bottom" matchWidth="true">
                              <HStack>
                                <PopoverAnchor>
                                  <Button size="sm" colorScheme="gray" onClick={() => addAllRcas(peerGroup.rcas.filter((x) => x.hasImported && x.hasData))}>
                                    Add all
                                    {' '}
                                    {peerGroup.name}
                                    {' '}
                                    RCAs
                                  </Button>
                                </PopoverAnchor>
                                <PopoverTrigger>
                                  <IconButton
                                    variant="ghost"
                                    colorScheme="brand.blue"
                                    size="sm"
                                    ml={3}
                                    icon={<Icon as={FiInfo} boxSize={5} />}
                                  />
                                </PopoverTrigger>
                              </HStack>
                              <PopoverContent>
                                <PopoverArrow />
                                <PopoverBody>
                                  {peerGroup.rcas.map((rca) => ((!rca.hasData && !rca.hasImported) ? (
                                    <Flex key={`rca_${rca.id}`} align="center">
                                      <Icon as={FiAlertCircle} mr={2} />
                                      <Text m={0} color="gray.400">{rca.name}</Text>
                                    </Flex>
                                  ) : (
                                    <Flex key={`rca_${rca.id}`} align="center">
                                      <Icon as={FiCheck} mr={2} color="green.600" />
                                      <Text m={0}>{rca.name}</Text>
                                    </Flex>
                                  )))}
                                </PopoverBody>
                              </PopoverContent>
                            </Popover>
                          </Flex>
                        </NetworkItem>
                      ))}
                    </ScrollableList>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button variant="outline" colorScheme="gray" mr={4} onClick={handleCancel}>
              Cancel
            </Button>
            <Button onClick={handleCompare}>Compare Networks</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
