import { useState, useEffect } from 'react';
import { useDeliveryPerformanceKPI } from '@src/modules/performance/context/map-api-hooks';
import councildata from '@src/modules/performance/views/map/CouncilData';
import { TrafficLightColours } from '../charts/ChartColours';
import TrafficLightWidget from './TrafficLightWidget';

function DeliveryPerformanceWidget({ ...props }) {
  const { data: performance } = useDeliveryPerformanceKPI();
  const [value, setValue] = useState('--');
  const [colour, setColour] = useState('GREY');

  useEffect(() => {
    setColour(Number.isNaN(performance.value) ? 'GREY' : councildata.mapValueToColourRange(performance.value, councildata.colours.deliveryPerformance));
    setValue(performance.value || 'No data');
  }, [performance]);

  return (
    <TrafficLightWidget
      title="Delivery Performance"
      subtext1="Delivery Grade"
      link="/performance/dashboard/delivery-performance"
      data={value}
      color={TrafficLightColours[colour]}
      {...props}
    />
  );
}

export default DeliveryPerformanceWidget;
