import { useQuery } from 'react-query';
import { useResetRecoilState, useSetRecoilState, useRecoilValue } from 'recoil';

import {
  availableDatabasesState,
  selectedDatabaseIdState,
  selectedLockYearState,
} from '@src/state/navContext';
import { queryStringDatabaseIdState, queryStringLockYearState } from '@src/state/queryString';
import { toNumber } from 'lodash';
import { useSettingsApi } from '@src/modules/settings/api/settings';

/**
 * @typedef {object} PortalNavProps
 * @prop {boolean} isLoading
 * @prop {PortalNavContext} context
 * @prop {NavMainMenu} mainMenu
 */

/**
 * @typedef {object} PortalNavContext
 * @prop {import('@src/api/useSettingsApi').NavContextDatabase[]} availableDatabases
 * @prop {import('@src/api/useSettingsApi').NavContextDatabase} selectedDatabase
 * @prop {string} selectedLockYear
 * @prop {(id: string) => void} changeSelectedDatabase
 * @prop {(val: string) => void} changeSelectedLockYear
 */

/**
 * @typedef {object} NavMainMenu
 * @prop {NavItem[]} items
 */

/**
 * @typedef {object} NavItem
 * @prop {NavLink} [link]
 * @prop {NavSection} [section]
 */

/**
 * @typedef {object} NavLink
 * @prop {boolean} [isActive]
 * @prop {string} [icon]
 * @prop {string} title
 * @prop {string} href
 * @prop {string} [onclick]
 */

/**
 * @typedef {object} NavSection
 * @prop {boolean} [isActive]
 * @prop {string} [icon]
 * @prop {string} title
 * @prop {NavItem[]} items
 */

export function usePortalNavContext() {
  const api = useSettingsApi();
  const setAvailableDatabases = useSetRecoilState(availableDatabasesState);
  const setSelectedDatabaseId = useSetRecoilState(selectedDatabaseIdState);
  const setSelectedLockYear = useSetRecoilState(selectedLockYearState);
  const selectedLockYear = useRecoilValue(selectedLockYearState);
  const resetLockYears = useResetRecoilState(selectedLockYearState);

  const queryStringDatabaseId = useRecoilValue(queryStringDatabaseIdState);
  const queryStringLockYear = useRecoilValue(queryStringLockYearState);

  const { isLoading, isSuccess } = useQuery(
    ['rca-selector-state'],
    async () => api.getRcaSelectorState(),
    {
      onSuccess: ({ databases, currentDatabaseId }) => {
        setAvailableDatabases(databases);
        if (currentDatabaseId) {
          setSelectedDatabaseId(currentDatabaseId);
        } else if (queryStringDatabaseId) {
          setSelectedDatabaseId(toNumber(queryStringDatabaseId));
        }
        const database = databases?.find((d) => d.id === currentDatabaseId);
        if (queryStringLockYear) {
          setSelectedLockYear(queryStringLockYear);
        } else if (selectedLockYear) {
          // Do nothing we already have a lock year
        } else if (database?.lockYears) {
          setSelectedLockYear(database.lockYears[database.lockYears.length - 1] || null);
        } else {
          resetLockYears();
        }
      },
    },
  );

  return {
    isLoading,
    isSuccess,
  };
}
