import { useAuthenticatedApi } from '@transport-insights/uikit';
import { useConfig } from '@src/context/config';

export default () => {
  const { PERFORMANCE_API_URL } = useConfig();
  const api = useAuthenticatedApi(PERFORMANCE_API_URL);

  return {
    async getActivityManagement(type, rcaId, lockYear) {
      const url = `/activity-management/${type}/${rcaId}/${lockYear}`;
      const res = await api.get(url);
      return res?.data || [];
    },
    async getServicePerformanceKPI(rcaId, lockYear) {
      const url = `/service-performance/service-performance-kpi/${rcaId}/${lockYear}`;
      const res = await api.get(url);
      return res?.data || [];
    },
    async getReport(name, rcaId, lockYear, framework) {
      const url = `${name}/${rcaId}/${lockYear}?framework=${framework || ''}`;
      const res = await api.get(url);
      return res?.data || [];
    },
    async getAnnualReports(rcaId) {
      const url = `/annual-reports/${rcaId}/`;
      const res = await api.get(url);
      return res?.data || [];
    },
    async downloadReport(fileUrl) {
      const url = '/annual-reports/download';
      const res = await api.post(url, { path: fileUrl }, {
        responseType: 'blob',
      });
      return res?.data || null;
    },
    async getActivityManagementPlans(rcaId) {
      const res = await api.get(`/activity-management-plans/${rcaId}/`);
      return res?.data || [];
    },
    async getMiscActivityManagementPlans(rcaId) {
      const url = `/activity-management-plans-misc/${rcaId}`;
      const res = await api.get(url);
      return res?.data || [];
    },
    async downloadActivityManagementPlan(rcaId, fileUrl) {
      const res = await api.post(`/activity-management-plans/${rcaId}/download`, { path: fileUrl }, {
        responseType: 'blob',
      });
      return res?.data || null;
    },
  };
};
