import {
  Container,
  Heading,
  Flex,
  useDisclosure,
} from '@chakra-ui/react';
import MainLayout from '@src/components/layouts/main';

import {
  useTceTrafficGroups,
} from '@src/modules/tce/src/context/trafficgroups';

import { councilSelectorEnabledState, lockYearsSelectorEnabledState } from '@src/state/navContext';
import AccessWarningDialog from '@tce/components/pages/import/components/AccessWarningDialog';
import { useRcaAccess } from '@tce/context/access';
import { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { useSetRecoilState } from 'recoil';
import TrafficGroupTable from './components/TrafficGroupTable';
import MoreDrawer from '../../shared/MoreDrawer';

export default function TrafficGroupPage() {
  const { isLoading, trafficgroups } = useTceTrafficGroups();
  const queryClient = useQueryClient();
  const { data: hasAccess } = useRcaAccess();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const setEnableCouncilSelector = useSetRecoilState(councilSelectorEnabledState);
  const setEnableLockyearSelector = useSetRecoilState(lockYearsSelectorEnabledState);

  // force refresh on the data on page load
  useEffect(() => {
    queryClient.invalidateQueries('tce-trafficgroups');
  }, [queryClient]);

  useEffect(() => {
    setEnableCouncilSelector(true);
    setEnableLockyearSelector(false);
  }, [setEnableCouncilSelector, setEnableLockyearSelector]);

  useEffect(() => {
    if (hasAccess === false) {
      onOpen();
    }
  });

  useEffect(() => () => {
    setEnableLockyearSelector(true);
  }, [setEnableLockyearSelector]);

  const onCancelDialog = () => {
    onClose();
    window.location.href = '/';
  };
  const sortedGroups = trafficgroups?.sort((a, b) => a.code.localeCompare(b.code));

  return (
    <MainLayout>
      <Container maxW="full">
        <Flex align="center" justify="space-between" mb={4}>
          <Heading as="h1">Traffic Groups</Heading>
          <MoreDrawer />
        </Flex>
        {hasAccess === true && (
          <TrafficGroupTable
            trafficGroups={sortedGroups || []}
            isLoading={isLoading}
          />
        )}
        {hasAccess === false && (
        <AccessWarningDialog isOpen={isOpen} onCancel={onCancelDialog} />
        )}
      </Container>
    </MainLayout>
  );
}
